package com.picme.sdk2.generated.authentication

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


class AuthenticationHandlerApiLive(val endpoints: AuthenticationHandlerEndpoints, val accessToken: suspend () -> String?): AuthenticationHandlerApi {

    override suspend fun continuePasswordReset(
        emailOrPhoneNumber: String,
        confirmationCode: String,
        newPassword: String,
    ): Unit = endpoints.continuePasswordReset(
        emailOrPhoneNumber = emailOrPhoneNumber,
        confirmationCode = confirmationCode,
        newPassword = newPassword,
        accessToken = accessToken,
    )

    override suspend fun continueSignUpUser(
        emailOrPhoneNumber: String,
        verificationCode: String,
        setTosRead: Boolean,
        name: String?,
    ): Unit = endpoints.continueSignUpUser(
        emailOrPhoneNumber = emailOrPhoneNumber,
        verificationCode = verificationCode,
        setTosRead = setTosRead,
        name = name,
        accessToken = accessToken,
    )

    override suspend fun resendAccountVerification(
        emailOrPhoneNumber: String,
    ): Unit = endpoints.resendAccountVerification(
        emailOrPhoneNumber = emailOrPhoneNumber,
        accessToken = accessToken,
    )

    override suspend fun requestPasswordReset(
        emailOrPhoneNumber: String,
    ): Unit = endpoints.requestPasswordReset(
        emailOrPhoneNumber = emailOrPhoneNumber,
        accessToken = accessToken,
    )

    override suspend fun signUpUser(
        emailOrPhoneNumber: String,
        password: String?,
        setTosRead: Boolean?,
        referrerUserId: UserId?,
        userName: String?,
    ): SignUpUserResponse = endpoints.signUpUser(
        emailOrPhoneNumber = emailOrPhoneNumber,
        password = password,
        setTosRead = setTosRead,
        referrerUserId = referrerUserId,
        userName = userName,
        accessToken = accessToken,
    )

    override suspend fun startAuthentication(
        emailOrPhoneNumber: String,
        password: String,
    ): StartAuthenticationResponse = endpoints.startAuthentication(
        emailOrPhoneNumber = emailOrPhoneNumber,
        password = password,
        accessToken = accessToken,
    )

    override suspend fun checkUsername(
        emailOrPhoneNumber: String,
    ): CheckUsernameResponse = endpoints.checkUsername(
        emailOrPhoneNumber = emailOrPhoneNumber,
        accessToken = accessToken,
    )

    override suspend fun getUserProfileUploadUrl(
        contentType: String,
    ): GetUserProfileUploadUrlResponse = endpoints.getUserProfileUploadUrl(
        contentType = contentType,
        accessToken = accessToken,
    )

    override suspend fun deleteUserProfilePicture(
    ): Unit = endpoints.deleteUserProfilePicture(
        accessToken = accessToken,
    )

    override suspend fun getUserProfileInfo(
        userId: UserId,
        collectionId: CollectionId?,
    ): GetUserProfileInfoResponse = endpoints.getUserProfileInfo(
        userId = userId,
        collectionId = collectionId,
        accessToken = accessToken,
    )

    override suspend fun getUserStateDataUrls(
    ): GetUserStateDataUrlsResponse = endpoints.getUserStateDataUrls(
        accessToken = accessToken,
    )

    override suspend fun setUserAttributes(
        name: String?,
        setTosRead: Boolean,
    ): SetUserAttributesResponse = endpoints.setUserAttributes(
        name = name,
        setTosRead = setTosRead,
        accessToken = accessToken,
    )

    override suspend fun changeUserPassword(
        oldPassword: String,
        newPassword: String,
    ): ChangeUserPasswordResponse = endpoints.changeUserPassword(
        oldPassword = oldPassword,
        newPassword = newPassword,
        accessToken = accessToken,
    )

    override suspend fun getMyUserInfo(
    ): GetMyUserInfoResponse = endpoints.getMyUserInfo(
        accessToken = accessToken,
    )

    override suspend fun refreshUserAuthentication(
        refreshToken: String,
    ): RefreshUserAuthenticationResponse = endpoints.refreshUserAuthentication(
        refreshToken = refreshToken,
        accessToken = accessToken,
    )

    override suspend fun createReferralInviteCode(
        name: String,
        clientInformation: String,
    ): CreateInviteCodeResponse = endpoints.createReferralInviteCode(
        name = name,
        clientInformation = clientInformation,
        accessToken = accessToken,
    )

    override suspend fun deleteUser(
    ): DeleteUserResponse = endpoints.deleteUser(
        accessToken = accessToken,
    )

    override suspend fun mergeIntoAccount(
        targetAccountEmailOrPhoneNumber: String,
        targetAccountPassword: String,
    ): MergeIntoAccountResponse = endpoints.mergeIntoAccount(
        targetAccountEmailOrPhoneNumber = targetAccountEmailOrPhoneNumber,
        targetAccountPassword = targetAccountPassword,
        accessToken = accessToken,
    )

    override suspend fun listMyReferrals(
    ): ListReferralsResponse = endpoints.listMyReferrals(
        accessToken = accessToken,
    )

    override suspend fun checkUserAuthentication(
    ): CheckUserAuthenticationResponse = endpoints.checkUserAuthentication(
        accessToken = accessToken,
    )

    override suspend fun invalidateUserTokens(
    ): Unit = endpoints.invalidateUserTokens(
        accessToken = accessToken,
    )

    override suspend fun getRightsOn(
        recordGlobalId: RecordGlobalId,
    ): GetRightsOnResponse = endpoints.getRightsOn(
        recordGlobalId = recordGlobalId,
        accessToken = accessToken,
    )

    override suspend fun authenticateAsGuest(
        setTosRead: Boolean,
        userName: String?,
    ): AuthenticateAsGuestResponse = endpoints.authenticateAsGuest(
        setTosRead = setTosRead,
        userName = userName,
        accessToken = accessToken,
    )
}
