package com.picme.layout


import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.CornerRadius
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.reactive.sharedSuspending
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.views.CollSubPage
import com.picme.views.CollectionLanding
import com.picme.views.trashActions


fun ViewWriter.toolbar() {
    val showToolbar = sharedSuspending {
        val curr = screenNavigator.currentScreen()
        if (curr is NoTopBar) return@sharedSuspending false
        if (curr is IncludeTopBar) return@sharedSuspending true

        if (curr is CollectionLanding) return@sharedSuspending (session()?.isVerifiedAccount() == true)

        (curr !is UnauthScreen && curr !is PicmeDialog)
    }
    bar - row {
        ::exists { showToolbar() && currentCollection() != null }
        spacing = 0.75.rem
        collectionListMenu()


        val title = shared { screenNavigator.currentScreen()?.title?.await() ?: "..." }


        expanding - stack {
            atStart - button {
                themeChoice += ThemeDerivation { it.copy(cornerRadii = CornerRadius.Constant(1.rem)).withoutBack }
                h4 {
                    ::exists { title().isNotEmpty() }
                    ::content { title() }
                }
                onClick {
                    CollectionState.setView(CollSubPage.Main)
                }
            }
        }

        centered - stack {
            ::exists{ title().isEmpty() }
            picmeIconDisplay()
        }

        val actionSection = shared {
            if (isSmallScreen()) ActionSection.None
            else if (CollectionState.currView() == CollSubPage.Trash) ActionSection.Trash
            else ActionSection.Collection
        }

        unpadded - stack {
            ::exists { actionSection() != ActionSection.None }
            spacing = 0.rem
            swapView {
                swapping(
                    current = { actionSection() },
                    views = {
                        when (it) {
                            ActionSection.Collection -> collectionButtonsTop()
                            ActionSection.Trash -> trashActions(false)
                            ActionSection.None -> {}
                        }
                    },
                )
            }
        }

        atEnd - userInfoPopover()
    }


    bar - row {
        ::exists { showToolbar() && currentCollection() == null }
        spacing = 0.75.rem
        collectionListMenu()

        expanding - space { }
        centered - stack {
            picmeIconDisplay()
        }

        expanding - space { }
        userInfoPopover()
    }
    separatorTheme - separator { ::exists { showToolbar() } }
}


enum class ActionSection { Collection, Trash, None }

