package com.picme.sdk2.generated

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*




import com.picme.sdk2.generated.ad.*
import com.picme.sdk2.generated.authentication.*
import com.picme.sdk2.generated.collection2.*
import com.picme.sdk2.generated.discussion.*
import com.picme.sdk2.generated.notification.*

/** A record containing the response from <see cref="M:PicMeApi.AdApis.RecordImpression(CloudFileSystems.ICloudFileSystem,System.String,System.String,PicMeApi.UserDataDetails,System.String)" />. **/

/** A list of ads to be displayed in the future. **/
@Serializable
data class ListAdsResponse(
    val ads: List<ListedAd> = listOf(), 
)

/** An entry for the ad list. **/
@Serializable
data class ListedAd(
    val size: AdSize = AdSize.values().first(), 
    val adId: String = "", 
    val uri: Uri = "", 
    val expiration: DateTime = Instant.fromEpochMilliseconds(0), 
)

/** An enumeration of sizes for ads. **/
object AdSizeSerializer: KSerializer<AdSize> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("ad.AdSize", PrimitiveKind.INT)
    
    override fun deserialize(decoder: Decoder): AdSize {
        return AdSize.byValue[decoder.decodeInt()] ?: AdSize.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: AdSize) {
        encoder.encodeInt(value.value)
    }
}
@Serializable(AdSizeSerializer::class)
enum class AdSize(val value: Int) {
/** A small ad, about 283x187 pixels. **/
    Small(0),
/** A medium ad, about 283x343 pixels. **/
    Medium(1),
/** A large ad.  ???x??? pixels. **/
    Large(2),
    ;
    companion object {
        val byValue = AdSize.values().associateBy { it.value }
    }
}


@Serializable
data class HttpRedirect(
    val location: Uri = "", 
)

/** A struct that holds a Compact user id. **/
@Serializable
@JvmInline
value class UserId(val raw: String = "")

/** A struct that holds a PicMe collection id. **/
@Serializable
@JvmInline
value class CollectionId(val raw: String = "")

/** A record that holds a link relationship type string.
            Link type strings can contain anything except for slash characters. **/
object LinkRelationshipTypeSerializer: KSerializer<LinkRelationshipType> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("authentication.LinkRelationshipType", PrimitiveKind.STRING)
    
    override fun deserialize(decoder: Decoder): LinkRelationshipType {
        return LinkRelationshipType.byValue[decoder.decodeString()] ?: LinkRelationshipType.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: LinkRelationshipType) {
        encoder.encodeString(value.value)
    }
}
@Serializable(LinkRelationshipTypeSerializer::class)
enum class LinkRelationshipType(val value: String) {
/** Gets the <see cref="T:SimpleDatabase.LinkRelationshipType" /> for an ownership relationship.
            This link type indicates that the primary entity owns (or co-owns) the secondary entity.
            The <see cref="T:SimpleDatabase.LinkRelationship" /> for this link type is a value obtained from <see cref="M:SimpleDatabase.LinkRelationship.FromEnum``1(``0)" /> where the <see cref="P:SimpleDatabase.LinkRelationshipType.Ownership" /> enumeration is the value. **/
    Ownership("o"),
/** Gets the <see cref="T:SimpleDatabase.LinkRelationshipType" /> for a rights relationship indicating what rights the primary entity has on the secondary entity itself.
            The <see cref="T:SimpleDatabase.LinkRelationship" /> for this link type is a value obtained from <see cref="M:SimpleDatabase.LinkRelationship.FromEnum``1(``0)" /> where the <see cref="P:SimpleDatabase.LinkRelationshipType.Rights" /> enumeration is the value. **/
    Rights("?"),
/** Gets the <see cref="T:SimpleDatabase.LinkRelationshipType" /> for a participation rights relationship, rights that the primary entity has not on the secondary entity itself, but on some 'participatory' subset of contents associated with the secondary entity (for example, the subset of uploads in a collection other than your own).
            The <see cref="T:SimpleDatabase.LinkRelationship" /> for this link type is a value obtained from <see cref="M:SimpleDatabase.LinkRelationship.FromEnum``1(``0)" /> where the <see cref="P:SimpleDatabase.LinkRelationshipType.Rights" /> enumeration is the value.
            Even when these rights are Rights.None, the user in the primary entity will have rights to upload to a collection and manage their own uploads. **/
    ParticipationRights("¿"),
/** Gets the <see cref="T:SimpleDatabase.LinkRelationshipType" /> for a hierarchical relationship, for example folders within folders.
            The entity types don't necessarily have to be the same.  The hierarchy could indicate collections or other objects under a user.
            The <see cref="T:SimpleDatabase.LinkRelationship" /> for this link type is currently undefined.  Any relationship indicates that the secondary object is a child of the parent object. **/
    Hierarchy("i,┴"),
/** Gets the <see cref="T:SimpleDatabase.LinkRelationshipType" /> for a mapping relationship, for example mapping an external identifier to a local object.
            The <see cref="T:SimpleDatabase.LinkRelationship" /> for this link type is currently undefined.  Any relationship indicates that the secondary object is the object identified by the external identifier. **/
    Map("⊶"),
    ;
    companion object {
        val byValue = LinkRelationshipType.values().associateBy { it.value }
    }
}

/** A record containing the response to Invite generating functions. **/
@Serializable
data class CreateInviteCodeResponse(
    val inviteCode: InviteCode = InviteCode(), 
)

/** A InviteCode record is a child record of either a collection or user parent record and describes the properties of a Invite code. **/
@Serializable
data class InviteCode(
    val inviteCodeId: InviteCodeId = InviteCodeId(""), 
    val inviteCodeGlobalId: RecordGlobalId = RecordGlobalId(""), 
    val created: DateTime = Instant.fromEpochMilliseconds(0), 
    val creatorUserId: UserId = UserId(""), 
    val modified: DateTime = Instant.fromEpochMilliseconds(0), 
    val name: String = "", 
    val clientInformation: String? = null, 
    val temporarilyDisabled: Boolean = false, 
    val start: DateTime? = null, 
    val end: DateTime? = null, 
    val linkActivator: LinkActivator? = null, 
)

/** A record containing information needed to activate one or more <see cref="T:SimpleDatabase.Link" />s.
            A <see cref="T:PicMeModel.LinkTemplate" /> has all the information needed to decide if the link should be created and what information it should contain. **/
@Serializable
data class LinkActivator(
    val linkTemplates: List<LinkTemplate> = listOf(), 
    val limit: UInt16 = 0, 
)

/** A record containing data about a <see cref="T:SimpleDatabase.Link" /> to create. **/
@Serializable
data class LinkTemplate(
    val relationshipType: LinkRelationshipType = LinkRelationshipType.values().first(), 
    val primary: RecordGlobalId = RecordGlobalId(""), 
    val secondary: RecordGlobalId? = null, 
    val relationship: LinkRelationship? = null, 
    val conflictResolution: LinkConflictResolution = LinkConflictResolution.values().first(), 
)

/** A multivalued enumeration that indicates how to resolve conflicts when creating a link from a <see cref="T:PicMeModel.LinkTemplate" />.
            The link's <see cref="P:PicMeModel.LinkTemplate.RelationshipType" /> always has to match to count as a conflict. **/
@Serializable
enum class LinkConflictResolutionEnum(val value: Int) {
/** Default conflict resolution (allow similar links as long as they differ in any way). **/
    Default(0),
/** If a similar link exists, the relationships should be merged using the default merge algorithm which assumes that the relationship is a multivalued enum, so values are merged using a bitwise OR operation.
            Also implies that the primary and secondary must match to count as a conflict. **/
    MergeRelationship(1),
/** Throw an exception if a conflicting link already exists. **/
    ThrowException(2),
/** The primary matching another link is required to count as a conflict. **/
    PrimaryMatches(4),
/** The secondary matching another link is required to count as a conflict. **/
    SecondaryMatches(8),
/** If a similar link exists, the relationships should be merged assuming that the relationship is an undelimited string enum. **/
    MergeRelationshipWithConcat(257),
/** Must be used with <see cref="F:PicMeModel.LinkConflictResolution.MergeRelationshipWithConcat" />, indicates that rather than an undelimited string, the string is comma-delimited. **/
    ConcatWithComma(512),
/** Must be used with <see cref="F:PicMeModel.LinkConflictResolution.MergeRelationshipWithConcat" />, indicates that rather than an undelimited string, the string is semicolon-delimited. **/
    ConcatWithSemicolon(1024),
    ;
    companion object {
        val byValue = LinkConflictResolutionEnum.values().associateBy { it.value }
    }
}
@Serializable
@JvmInline
value class LinkConflictResolution(val value: Int) {
    fun getValues(): Set<LinkConflictResolutionEnum> = LinkConflictResolutionEnum.values().filter { (it.value and value == it.value)  }.toSet()
    companion object {
        fun fromLinkConflictResolution(values: Set<LinkConflictResolutionEnum>): LinkConflictResolution {
            val perms = values.fold(0) {acc, values -> acc or values.value}
            return LinkConflictResolution(perms)
        }
        fun values() = LinkConflictResolutionEnum.values().map { fromLinkConflictResolution(setOf(it)) }
    }
}

/** A record struct that holds a link type string.
            Link type strings can contain anything except for slash characters.
            Link types can be easily converted to or from any enum type using <see cref="M:SimpleDatabase.LinkRelationship.FromEnum``1(``0)" /> and <see cref="M:SimpleDatabase.LinkRelationship.ToEnum``1" />. **/
object LinkRelationshipSerializer: KSerializer<LinkRelationship> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("authentication.LinkRelationship", PrimitiveKind.STRING)
    
    override fun deserialize(decoder: Decoder): LinkRelationship {
        return LinkRelationship.byValue[decoder.decodeString()] ?: LinkRelationship.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: LinkRelationship) {
        encoder.encodeString(value.value)
    }
}
@Serializable(LinkRelationshipSerializer::class)
enum class LinkRelationship(val value: String) {
/** Gets the default relationship. **/
    Default(""),
    ;
    companion object {
        val byValue = LinkRelationship.values().associateBy { it.value }
    }
}

/** A struct that holds a Compact Invite Code ID. **/
@Serializable
@JvmInline
value class InviteCodeId(val raw: String = "")

/** A structured replacement for <see cref="T:SimpleDatabase.RecordIdentifier" /> that contains the same data, a type-qualified and parent-qualified global identifier for a database record.
            Records may be children of other records, and this identifier contains the path to the parent in addition to the path to the child record.
            An example of a record that is a child of another record is an upload within a collection. **/
@Serializable
data class RecordGlobalId(
    val scope: String = "",
    val typeId: String = "",
    val localId: String? = null, 
)

/** A struct that holds a string of text that should be indexed as words rather than as a single complete string. **/
@Serializable
@JvmInline
value class Text(val raw: String = "")

/** A struct that holds an HTTP MimeType value. **/
@Serializable
@JvmInline
value class MimeType(val raw: String = "")

/** A struct that holds a date-time range.  Serialized as the ISO 8601 start and end dates separated by two dashes. **/
@Serializable
@JvmInline
value class DateTimeRange(val raw: String = "")

/** A multivalued enumeration of rights that may be assigned between entities when they are *not* owned outright.
            Do not change the names here.  They are converted to character representations based on the names here. **/
@Serializable
enum class RightsEnum(val value: Int) {
/** The primary entity is not allowed any kind of access to the secondary entity. **/
    None(0),
/** The primary entity is allowed to list the secondary entity. **/
    List(1),
/** The primary entity is allowed to read the secondary entity. **/
    Read(2),
/** The primary entity is allowed to create the secondary entity. **/
    Create(4),
/** The primary entity is allowed to update the secondary entity. **/
    Update(8),
/** The primary entity is allowed to delete the secondary entity. **/
    Delete(16),
/** The primary entity is allowed to share their other rights to the secondary entity with others. **/
    ShareWithOthers(32),
/** The primary entity is allowed to do anything with the secondary entity. **/
    Everything(-1),
    ;
    companion object {
        val byValue = RightsEnum.values().associateBy { it.value }
    }
}
@Serializable
@JvmInline
value class Rights(val value: Int) {
    fun getValues(): Set<RightsEnum> = RightsEnum.values().filter { (it.value and value == it.value)  }.toSet()
    companion object {
        fun fromRights(values: Set<RightsEnum>): Rights {
            val perms = values.fold(0) {acc, values -> acc or values.value}
            return Rights(perms)
        }
        fun values() = RightsEnum.values().map { fromRights(setOf(it)) }
    }
}

/** A record that contains a tumbler, a string that allows for user-controlled sorting, 
            such that the ordinally-sorted strings are in the desired order and 
            an algorithm can generate new tumblers that sort between existing tumblers.
            If the caller wants to generate new tumblers that sort between two existing tumblers,
            they will need to implement this algorithm, or at least restrict themselves to the characters that are valid in tumblers.
            Those characters are: 7-bit ASCII characters excluding NUL, /, and DEL. **/
@Serializable
@JvmInline
value class Tumbler(val raw: String = "")

/** A record containing the response to a request to create a QR code image. **/
@Serializable
data class CreateQrCodeImageResponse(
    val contentType: MimeType = MimeType(""), 
    val fileExtension: String = "", 
    val base64Encoded: String = "", 
)

