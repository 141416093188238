package com.lightningkite.kiteui.views.direct

import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.l2.dialog
import com.lightningkite.kiteui.views.l2.overlayStack
import kotlin.contracts.InvocationKind
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.contract

@ViewDsl
@OptIn(ExperimentalContracts::class)
inline fun ViewWriter.subtext(crossinline setup: TextView.() -> Unit = {}): TextView {
    contract { callsInPlace(setup, InvocationKind.EXACTLY_ONCE) }
    return text {
        themeChoice += SubtextSemantic
        setup(this)
    }
}

@ViewDsl
@OptIn(ExperimentalContracts::class)
inline fun ViewWriter.h1(crossinline setup: TextView.() -> Unit = {}): TextView {
    contract { callsInPlace(setup, InvocationKind.EXACTLY_ONCE) }
    return text {
        themeChoice += HeaderSemantic + H1Semantic
        setup(this)
    }
}

@ViewDsl
@OptIn(ExperimentalContracts::class)
inline fun ViewWriter.h2(crossinline setup: TextView.() -> Unit = {}): TextView {
    contract { callsInPlace(setup, InvocationKind.EXACTLY_ONCE) }
    return text {
        themeChoice += HeaderSemantic + H2Semantic
        setup(this)
    }
}

@ViewDsl
@OptIn(ExperimentalContracts::class)
inline fun ViewWriter.h3(crossinline setup: TextView.() -> Unit = {}): TextView {
    contract { callsInPlace(setup, InvocationKind.EXACTLY_ONCE) }
    return text {
        themeChoice += HeaderSemantic + H3Semantic
        setup(this)
    }
}

@ViewDsl
@OptIn(ExperimentalContracts::class)
inline fun ViewWriter.h4(crossinline setup: TextView.() -> Unit = {}): TextView {
    contract { callsInPlace(setup, InvocationKind.EXACTLY_ONCE) }
    return text {
        themeChoice += HeaderSemantic + H4Semantic
        setup(this)
    }
}

@ViewDsl
@OptIn(ExperimentalContracts::class)
inline fun ViewWriter.h5(crossinline setup: TextView.() -> Unit = {}): TextView {
    contract { callsInPlace(setup, InvocationKind.EXACTLY_ONCE) }
    return text {
        themeChoice += HeaderSemantic + H5Semantic
        setup(this)
    }
}

@ViewDsl
@OptIn(ExperimentalContracts::class)
inline fun ViewWriter.h6(crossinline setup: TextView.() -> Unit = {}): TextView {
    contract { callsInPlace(setup, InvocationKind.EXACTLY_ONCE) }
    return text {
        themeChoice += HeaderSemantic + H6Semantic
        setup(this)
    }
}

@ViewDsl
fun ViewWriter.h1(text: String) = h1 { content = text }

@ViewDsl
fun ViewWriter.h2(text: String) = h2 { content = text }

@ViewDsl
fun ViewWriter.h3(text: String) = h3 { content = text }

@ViewDsl
fun ViewWriter.h4(text: String) = h4 { content = text }

@ViewDsl
fun ViewWriter.h5(text: String) = h5 { content = text }

@ViewDsl
fun ViewWriter.h6(text: String) = h6 { content = text }

@ViewDsl
fun ViewWriter.text(text: String) = text { content = text }

@ViewDsl
fun ViewWriter.subtext(text: String) = subtext { content = text }

// TODO: Button with working indicator

fun ViewWriter.confirmDanger(
    title: String,
    body: String,
    actionName: String = "OK",
    action: suspend () -> Unit
) {
    dialog {
        col {
            h2(title)
            text(body)
            row {
                expanding - buttonTheme - button {
                    centered - text("Cancel")
                    onClick {
                        closePopovers()
                    }
                }
                expanding - danger - buttonTheme - button {
                    centered - text(actionName)
                    onClick {
                        action()
                        closePopovers()
                    }
                }
            }
        }
    }
}

fun ViewWriter.alert(
    title: String,
    body: String,
) {
    dialog {
        col {
//                    ignoreInteraction = false
            h2(title)
            text(body)
            row {
                expanding - danger - button {
                    centered - h6("OK")
                    onClick {
                        screenNavigator.dismiss()
                    }
                }
            }
        }
    }
}


@Deprecated("Use a shared action instead")
fun Button.onClickAssociatedField(
    field: TextInput,
    title: String = "Submit",
    icon: Icon = Icon.done,
    action: suspend () -> Unit
) {
    val action = Action(
        title = title,
        icon = icon,
        action = action
    )
    field.action = action
    this.action = action
}

@Deprecated("Use a shared action instead")
fun Button.onClickAssociatedField(
    field: NumberInput,
    title: String = "Submit",
    icon: Icon = Icon.done,
    action: suspend () -> Unit
) {
    val action = Action(
        title = title,
        icon = icon,
        action = action
    )
    field.action = action
    this.action = action
}
