package com.picme.sdk2.generated.notification

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


object NotificationHandlerEndpointsSerializer : KSerializer<NotificationHandlerEndpoints> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("NotificationHandlerApi", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: NotificationHandlerEndpoints) {
        encoder.encodeString(value.urlWithTrailingSlash)
    }

    override fun deserialize(decoder: Decoder): NotificationHandlerEndpoints {
        val string = decoder.decodeString()
        return NotificationHandlerEndpoints(string)
    }
}


@Serializable(with = NotificationHandlerEndpointsSerializer::class)
data class NotificationHandlerEndpoints(val urlWithTrailingSlash: String) {

    val url = urlWithTrailingSlash.trimEnd('/')
/** Sets one or more notification subscription configurations. **/
    suspend fun putNotificationSubscriptionConfigurations(
        subscriptions: List<NotificationSubscription>,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/notification",
        method = HttpMethod.PUT,
        accessToken = accessToken,
        parameters = mapOf(
            "subscriptions" to subscriptions.toParamString(),
        ).filter { it.value != "null" },
    )
/** Lists the user's notification subscription configurations. **/
    suspend fun listNotificationSubscriptionConfigurations(
        accessToken: suspend () -> String? = { null }
    ): ListNotificationSubscriptionConfigurationsResponse = fetch(
        url = "$url/notification",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )
}

