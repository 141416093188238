package com.picme.sdk2.generated.collection2

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


object CollectionHandler2EndpointsSerializer : KSerializer<CollectionHandler2Endpoints> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("CollectionHandler2Api", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: CollectionHandler2Endpoints) {
        encoder.encodeString(value.urlWithTrailingSlash)
    }

    override fun deserialize(decoder: Decoder): CollectionHandler2Endpoints {
        val string = decoder.decodeString()
        return CollectionHandler2Endpoints(string)
    }
}


@Serializable(with = CollectionHandler2EndpointsSerializer::class)
data class CollectionHandler2Endpoints(val urlWithTrailingSlash: String) {

    val url = urlWithTrailingSlash.trimEnd('/')

    suspend fun getDeletedUpload(
        collectionId: CollectionId,
        uploadId: UploadId,
        accessToken: suspend () -> String? = { null }
    ): GetDeletedUploadResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/deletedUpload/${uploadId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun listLinksFrom(
        linkRelationshipType: LinkRelationshipType,
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): ListLinksToResponse2 = fetch(
        url = "$url/linksFrom/${linkRelationshipType.toParamString()}/${collectionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun deleteCollectionCoverPhoto(
        collectionId: CollectionId,
        photoId: String,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/collection/${collectionId.toParamString()}/coverPhoto/${photoId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun listLinksTo(
        linkRelationshipType: LinkRelationshipType,
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): ListLinksToResponse2 = fetch(
        url = "$url/linksTo/${linkRelationshipType.toParamString()}/${collectionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun getUpload(
        collectionId: CollectionId,
        uploadId: UploadId,
        accessToken: suspend () -> String? = { null }
    ): GetUploadResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/upload/${uploadId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun patchUpload(
        collectionId: CollectionId,
        uploadId: UploadId,
        body: PatchUploadBody,
        accessToken: suspend () -> String? = { null }
    ): PatchUploadResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/upload/${uploadId.toParamString()}",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )

    suspend fun deleteUpload(
        collectionId: CollectionId,
        uploadId: UploadId,
        accessToken: suspend () -> String? = { null }
    ): DeleteUploadResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/upload/${uploadId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun copyUpload(
        caption: String?,
        sourceCollectionId: CollectionId,
        sourceUploadId: UploadId,
        destinationCollectionId: CollectionId,
        anonymous: Boolean,
        allowDuplicates: Boolean,
        accessToken: suspend () -> String? = { null }
    ): CopyUploadResponse2 = fetch(
        url = "$url/collection/${destinationCollectionId.toParamString()}/upload/from",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "caption" to caption.toParamString(),
            "sourceCollectionId" to sourceCollectionId.toParamString(),
            "sourceUploadId" to sourceUploadId.toParamString(),
            "anonymous" to anonymous.toParamString(),
            "allowDuplicates" to allowDuplicates.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun getCollectionModificationStamp(
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): GetCollectionModificationStampResponse2 = fetch(
        url = "$url/collectionModificationStamp/${collectionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun putCollectionCoverPhoto(
        collectionId: CollectionId,
        contentType: String,
        accessToken: suspend () -> String? = { null }
    ): PutCollectionCoverPhotoResponse = fetch(
        url = "$url/collection/${collectionId.toParamString()}/coverPhoto/urls",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "contentType" to contentType.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun listDeletedUploads(
        collectionId: CollectionId,
        continuation: String?,
        uploadsPerPage: Int32?,
        accessToken: suspend () -> String? = { null }
    ): ListDeletedUploadsResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/deletedUpload",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "continuation" to continuation.toParamString(),
            "uploadsPerPage" to uploadsPerPage.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun restoreDeletedUploads(
        collectionId: CollectionId,
        uploadIds: List<UploadId>?,
        accessToken: suspend () -> String? = { null }
    ): RestoreDeletedUploadsResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/deletedUpload",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "uploadIds" to uploadIds.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun listUploadKeywords(
        collectionId: CollectionId,
        propertyPath: String,
        keyWordPrefix: String,
        accessToken: suspend () -> String? = { null }
    ): ListUploadKeywordsResponse = fetch(
        url = "$url/collection/${collectionId.toParamString()}/uploadTerms",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "propertyPath" to propertyPath.toParamString(),
            "keyWordPrefix" to keyWordPrefix.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun getOrStartPartialZipOfOriginals(
        collectionId: CollectionId,
        uploadIds: List<UploadId>,
        accessToken: suspend () -> String? = { null }
    ): GetOrStartPartialZipOfOriginalsResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/zip/partial",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "uploadIds" to uploadIds.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun getOrStartFullZipOfOriginals(
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): GetOrStartFullZipOfOriginalsResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/zip/full",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun sharingConnect(
        authorization: CollectionSharingAuthorizationCode,
        collectionId: String,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/collection/${collectionId.toParamString()}/sharees",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "authorization" to authorization.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun createUpload(
        collectionId: CollectionId,
        body: CreateUploadBody,
        allowDuplicates: Boolean,
        accessToken: suspend () -> String? = { null }
    ): CreateUploadResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/upload",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "allowDuplicates" to allowDuplicates.toParamString(),
        ).filter { it.value != "null" },
        body = body,
    )

    suspend fun listUploads(
        collectionId: CollectionId,
        filterQuery: UploadQuery?,
        continuation: String?,
        uploadsPerPage: Int32?,
        accessToken: suspend () -> String? = { null }
    ): ListUploadsResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/upload",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "filterQuery" to filterQuery.toParamString(),
            "continuation" to continuation.toParamString(),
            "uploadsPerPage" to uploadsPerPage.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun deleteAllUploads(
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): DeleteAllUploadsResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/upload",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun requestConnect(
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/collection/${collectionId.toParamString()}/guests",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun getCollectionRights(
        collectionId: CollectionId,
        targetUserId: UserId?,
        accessToken: suspend () -> String? = { null }
    ): GetCollectionRightsResponse2 = fetch(
        url = "$url/collectionRights/${collectionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "targetUserId" to targetUserId.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun revokeRights(
        collectionId: CollectionId,
        userId: UserId,
        accessToken: suspend () -> String? = { null }
    ): RevokeRightsResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}/rights",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
            "userId" to userId.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun modifyRights(
        collectionId: CollectionId,
        userId: UserId,
        newRights: Rights,
        accessToken: suspend () -> String? = { null }
    ): ModifyRightsResponse = fetch(
        url = "$url/collection/${collectionId.toParamString()}/rights",
        method = HttpMethod.PUT,
        accessToken = accessToken,
        parameters = mapOf(
            "userId" to userId.toParamString(),
            "newRights" to newRights.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun getCollection(
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): GetCollectionResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun patchCollection(
        collectionId: CollectionId,
        body: PatchCollectionBody,
        accessToken: suspend () -> String? = { null }
    ): PatchCollectionResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )

    suspend fun deleteCollection(
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): DeleteCollectionResponse2 = fetch(
        url = "$url/collection/${collectionId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun activateInviteCode(
        inviteId: InviteCodeId,
        accessToken: suspend () -> String? = { null }
    ): ActivateInviteCodeResponse = fetch(
        url = "$url/invite/${inviteId.toParamString()}/activate",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun generateQrCodePng(
        hostnamePrefix: String,
        pathAndQueryStringPattern: String,
        useLogo: Boolean,
        colorLogo: Boolean,
        pixels: Int32,
        accessToken: suspend () -> String? = { null }
    ): CreateQrCodeImageResponse = fetch(
        url = "$url/pngqr64/${hostnamePrefix.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "pathAndQueryStringPattern" to pathAndQueryStringPattern.toParamString(),
            "useLogo" to useLogo.toParamString(),
            "colorLogo" to colorLogo.toParamString(),
            "pixels" to pixels.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun listActivatedUsers(
        inviteId: InviteCodeId,
        accessToken: suspend () -> String? = { null }
    ): ListActivatedUsersResponse = fetch(
        url = "$url/invite/${inviteId.toParamString()}/users",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun listUsers(
        collectionId: CollectionId,
        accessToken: suspend () -> String? = { null }
    ): ListUsersResponse2 = fetch(
        url = "$url/users/${collectionId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun generateQrCodeSvg(
        hostnamePrefix: String,
        pathAndQueryStringPattern: String,
        useLogo: Boolean,
        colorLogo: Boolean,
        accessToken: suspend () -> String? = { null }
    ): CreateQrCodeImageResponse = fetch(
        url = "$url/qr64/${hostnamePrefix.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "pathAndQueryStringPattern" to pathAndQueryStringPattern.toParamString(),
            "useLogo" to useLogo.toParamString(),
            "colorLogo" to colorLogo.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun getInviteCode(
        inviteId: InviteCodeId,
        accessToken: suspend () -> String? = { null }
    ): GetInviteCodeResponse = fetch(
        url = "$url/invite/${inviteId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun patchInviteCode(
        inviteId: InviteCodeId,
        body: PatchInviteCodeBody,
        accessToken: suspend () -> String? = { null }
    ): CreateInviteCodeResponse = fetch(
        url = "$url/invite/${inviteId.toParamString()}",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )

    suspend fun deleteInviteCode(
        inviteId: InviteCodeId,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/invite/${inviteId.toParamString()}",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun createRequestInviteCode(
        collectionGlobalId: RecordGlobalId,
        name: String,
        clientInformation: String,
        accessToken: suspend () -> String? = { null }
    ): CreateInviteCodeResponse = fetch(
        url = "$url/requestInvite",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "collectionGlobalId" to collectionGlobalId.toParamString(),
            "name" to name.toParamString(),
            "clientInformation" to clientInformation.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun createSharingInviteCode(
        collectionGlobalId: RecordGlobalId,
        name: String,
        clientInformation: String,
        rightsToOtherUsersUploads: Rights,
        accessToken: suspend () -> String? = { null }
    ): CreateInviteCodeResponse = fetch(
        url = "$url/sharingInvite",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "collectionGlobalId" to collectionGlobalId.toParamString(),
            "name" to name.toParamString(),
            "clientInformation" to clientInformation.toParamString(),
            "rightsToOtherUsersUploads" to rightsToOtherUsersUploads.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun createCollection(
        body: CreateCollectionBody,
        accessToken: suspend () -> String? = { null }
    ): CreateCollectionResponse2 = fetch(
        url = "$url/collection",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
        body = body,
    )

    suspend fun listCollections(
        filters: List<String>?,
        itemsPerPage: Int32,
        continuation: String?,
        forUserId: UserId?,
        accessToken: suspend () -> String? = { null }
    ): ListCollectionsResponse2 = fetch(
        url = "$url/collection",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "filters" to filters.toParamString(),
            "itemsPerPage" to itemsPerPage.toParamString(),
            "continuation" to continuation.toParamString(),
            "forUserId" to forUserId.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun recordImpression(
        adId: String,
        collectionId: String?,
        contextId: String?,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/impression",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "adId" to adId.toParamString(),
            "collectionId" to collectionId.toParamString(),
            "contextId" to contextId.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun createInviteCode(
        body: CreateInviteCodeBody,
        linkParentRecord: RecordGlobalId?,
        accessToken: suspend () -> String? = { null }
    ): CreateInviteCodeResponse = fetch(
        url = "$url/invite",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "linkParentRecord" to linkParentRecord.toParamString(),
        ).filter { it.value != "null" },
        body = body,
    )

    suspend fun listInviteCodes(
        linkRelationshipType: LinkRelationshipType?,
        linkPrimaryGlobalId: RecordGlobalId?,
        accessToken: suspend () -> String? = { null }
    ): ListInviteCodesResponse = fetch(
        url = "$url/invite",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "linkRelationshipType" to linkRelationshipType.toParamString(),
            "linkPrimaryGlobalId" to linkPrimaryGlobalId.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun listAds(
        collectionId: String?,
        unitTest: Boolean,
        accessToken: suspend () -> String? = { null }
    ): ListAdsResponse = fetch(
        url = "$url/ads",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "collectionId" to collectionId.toParamString(),
            "unitTest" to unitTest.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun adServe(
        collectionId: String?,
        adSize: AdSize?,
        contextId: String?,
        unitTest: Boolean,
        accessToken: suspend () -> String? = { null }
    ): RedirectResponse = fetch(
        url = "$url/ad",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "collectionId" to collectionId.toParamString(),
            "adSize" to adSize.toParamString(),
            "contextId" to contextId.toParamString(),
            "unitTest" to unitTest.toParamString(),
        ).filter { it.value != "null" },
    )
}

