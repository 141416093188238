package com.picme.views

import com.lightningkite.kiteui.RequestBodyFile
import com.lightningkite.kiteui.models.Action
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.viewDebugTarget
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.Retainable
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.PatchCollectionBody
import kotlinx.coroutines.launch

class CollectionSettings(val collectionId: CollectionId, val onBack: suspend () -> Unit) : PicmeDialog() {
    override val title = Constant("Collection Settings")
    override val withinCollContext = true
    override fun ViewWriter.topBar(close: suspend () -> Unit) = dialogTopBar(
        title = title,
        onBack = onBack,
    )

    private val collName = Property("")
    private val message = Property("")
    private val imageId = Property("")

    val collection = shared {
        session.awaitNotNull().collection2.getCollectionLive(collectionId)()
    }

    override fun ViewWriter.content() {
        val chosenImage = Property(ImageUploadInfo())

         scrolls - padded - col {
            launch {
                val collection = collection()
                collName set collection.collection.name
                message set collection.collection.message
                imageId set (collection().collection.coverPhotoVersionStamp?.raw ?: "")
                chosenImage set ImageUploadInfo(original = collection().getCoverPhotoUri.imageIfExists())
            }

              col {
                 spacing = 1.5.rem
                 styledTextField {
                    label = "Collection Name"
                    hint = "Name"
                    content bind collName
                    field.action = Action("Done", Icon.info) { }
                }

                chooseCoverIcon(chosenImage)

                styledTextField {
                    label = "Message to Guest uploaders"
                    hint = "Message (optional)"
                    content bind message
                    field.action = Action("Done", Icon.info) { }
                }

                actionOrCancelSection(
                    onCancel = onBack,
                    actionButton = {
                        importantButton("Save", enabled = shared { collName().isNotEmpty() }) {
                            println("onSave")
                            println("Reset ${chosenImage()}")
                            chosenImage().newImage?.let {
                                session.awaitNotNull().collection2.putCollectionCoverPhoto(
                                    collectionId = collectionId,
                                    body = RequestBodyFile(it),
                                    tempUri = null,
                                    onProgress = {}
                                )
                            }
                            if (chosenImage().deleteOriginal && imageId().isNotBlank()) {
                                session.awaitNotNull().collection2.deleteCollectionCoverPhoto(
                                    collectionId = collectionId,
                                    photoId =imageId()
                                )
                            }

                            session.awaitNotNull().collection2.patchCollection(
                                collectionId = collectionId,
                                body = PatchCollectionBody(
                                    name = Retainable(collName()),
                                    message = Retainable(message())
                                )
                            )
                            onBack()
                        }
                    }
                )

                space()
                centered - compact - danger - button {
                    text { content = "Delete collection" }
                    onClick {
                        showConfirmDialog(
                            title = "Confirm Delete",
                            content = "This collection will be permanently deleted.",
                            warning = "This action cannot be undone.",
                            confirmLabel = "Delete",
                            onConfirm = {
                                session.awaitNotNull().collection2.deleteCollection(collectionId)
                                currentCollection set null
                                navigateToCollOrLanding(notThisCollection = collectionId)
                            }
                        )
                    }
                }
            }

             //space not sure why the space with multiplier isn't working
            sizeConstraints(height = 5.rem) - col {

            }
        }
    }
}