package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.printStackTrace2
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.Session
import com.picme.sdk2.unauthApi
import com.lightningkite.kiteui.models.*
import kotlinx.coroutines.launch


@Routable("log-in")
object LogInScreen : Screen, UnauthScreen {
    override val title get() = Constant("")
    override fun ViewWriter.render() {
        val password = Property("")

        val submitError = Property(false)
        val emailError: Property<String?> = Property(null)
        val passwordError: Property<String?> = Property(null)

        authScreen("Log in", Constant(true)) {
            onlyWhen { submitError() } - danger - text {
                content = "Invalid email/password combination. Check your password and try again."
                align = Align.Center
            }

            reactiveSuspending {
                if (emailOrPhone().isValidEmailOrPhone()) {
                    emailError set null
                }
            }

            val tf: TextField

            col {
                spacing = 0.dp
                styledTextField {
                    label = "Email or Phone"
                    hint = "Email or Phone"
                    content bind emailOrPhone
                    field.keyboardHints = KeyboardHints.email
                    error bind emailError
                }
                styledPassword {
                    label = "Password"
                    hint = "Password"
                    content bind password
                    error bind passwordError
                    field.keyboardHints = KeyboardHints.password
                    field.requestFocus()
                    tf = this.field
                }
            }

            suspend fun login() {
                passwordError set null
                emailError set null
                submitError set false
                if (emailOrPhone().isBlank()) {
                    emailError set "Required"
                } else if (!emailOrPhone().isValidEmailOrPhone()) {
                    emailError set "Please enter a valid email or phone number"
                } else if (password().isBlank() || password().length < 8) {
                    passwordError set "Password must be at least 8 characters long"
                } else {
                    try {
                        unauthApi().authenticationHandler.startAuthentication(
                            emailOrPhoneNumber = emailOrPhone().lowercase(),
                            password = password()
                        ).let { startAuth ->
                            val auth = startAuth.successfulAuthentication ?: return@let
                            val accepted = acceptingInvite()?.alreadyAccepted == true

                            val newSession = if (session() != null && accepted) {
                                session()!!.authentication.mergeIntoAccount(
                                    targetAccountEmailOrPhoneNumber = emailOrPhone().lowercase(),
                                    targetAccountPassword = password()
                                ).let { merged ->
                                    Session(
                                        unauthApi(),
                                        merged.successfulAuthentication!!.authenticated,
                                        merged.successfulAuthentication.authenticatedUser
                                    )
                                }
                            } else {
                                Session(unauthApi(), auth.authenticated, auth.authenticatedUser)
                            }
                            // Don't  navigate to collection detail until collections are loaded in.
                            val all = newSession.collection2.listCollectionsLive().all()
                            println(all.size)

                            session set newSession


                            val shouldAccept =
                                acceptingInvite()?.qrCode?.clientInfo()?.type?.let {
                                    it != InviteType.Referral
                                } ?: false

                            if (shouldAccept) acceptQrCode()
                            else {
                                if (startAuth.successfulAuthentication.authenticatedUser.name.isBlank()) {
                                    screenNavigator.navigate(FinishCreateAccount)
                                } else {
                                    navigateToCollOrLanding()
                                }
                                acceptingInvite set null
                            }

                        }
                    } catch (e: Exception) {
                        e.printStackTrace2()
                        submitError set true
                    }
                }
            }

            actionOrCancelSection(
                onCancel = { screenNavigator.navigate(LoginOrSignUp()) },
                actionButton = {
                    importantButton("Log In", field = tf, onClick = ::login)
                }
            )
            centered - blueLink - button {
                text("Forgot Password?")
                onClick { screenNavigator.navigate(ResetPassword) }
            }
        }
    }
}