package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.sdk2.generated.collection2.CollectionUserData
import com.picme.views.share.guestIconAndName
import kotlinx.coroutines.launch

class Filters(val onBack: suspend () -> Unit) : PicmeDialog() {
    override val withinCollContext = true
    override fun ViewWriter.topBar(close: suspend () -> Unit) = dialogTopBar(
        title = title,
        onBack = onBack,
    )

    override val title = Constant("Filters")

    val selectedTab = Property(SelectedTab.First)
    val filterDate = Property<FilterDate?>(null)
    val filterGuests = Property<List<CollectionUserData>>(listOf())


    override fun ViewWriter.content() {
        expanding - padded - col {

            launch {
                val date = CollectionState.filterDate()
                val guests = CollectionState.filterGuests()
                if (date != null) selectedTab set SelectedTab.First
                if (guests.isNotEmpty()) selectedTab set SelectedTab.Second

                filterDate set date
                filterGuests set guests

            }

            tabSelect(selectedTab, "Date", "Guests")
            expanding - sizeConstraints(minHeight = 20.rem, maxHeight = 32.rem) - swapView {
                swapping(
                    current = { selectedTab() },
                    views = {
                        when (it) {
                            SelectedTab.First -> dateFilter(filterDate)
                            SelectedTab.Second -> guestFilter(filterGuests)
                        }
                    }
                )
            }

            actionOrCancelSection(
                onCancel = onBack,
                actionButton = {
                    importantButton("Apply") {
                        filterDate()?.let { CollectionState.filterDate set it }
                        CollectionState.filterGuests set filterGuests()
                        onBack()
                    }
                }
            )
        }
    }
}


fun ViewWriter.dateFilter(selected: Property<FilterDate?>) = col {
    val error = Property("")
    text("Date uploaded")
    atStart - sizeConstraints(width = 12.rem) - card - menuButton {
        stack {
            atStart - icon { source = PIcon.calendar }
            centered - text { ::content { selected()?.type?.toString() ?: "Select a date" } }
            atEnd - icon { source = Icon.dropdown }

        }
        requireClick = true
        opensMenu {
            sizeConstraints(width = 12.rem) - col {
                DateOptions.entries.forEach { dateOption ->
                    popoverItemTheme - button {

                        dynamicTheme {
                            if (selected()?.type == dateOption) ThemeDerivation {
                                it.copy(background = Color.menuIconBg).withBack
                            }
                            else null
                        }

                        ::exists { dateOption != DateOptions.Custom }
                        atStart - text(dateOption.toString())
                        onClick {
                            selected set FilterDate(type = dateOption)
                            closePopovers()
                        }
                    }
                }
                separatorTheme - separator { }

                popoverItemTheme - button {
                    dynamicTheme {
                        if (selected()?.type == DateOptions.Custom) ThemeDerivation {
                            it.copy(background = Color.menuIconBg).withBack
                        }
                        else null
                    }

                    atStart - text {
                        content = DateOptions.Custom.toString()
                    }
                    onClick {
                        selected set (selected()?.copy(type = DateOptions.Custom)
                            ?: FilterDate(type = DateOptions.Custom))
                        closePopovers()
                        dialogGeneric { close ->


                            h4("Custom Date Range")
                            col {
                                localDateField {
                                    content bind selected.lens(
                                        get = { it?.customStart },
                                        modify = { old, value -> old?.copy(customStart = value) }
                                    )
                                }
                                bold - subtext("to")
                                atTop - localDateField {
                                    content bind selected.lens(
                                        get = { it?.customEnd },
                                        modify = { old, value -> old?.copy(customEnd = value) }
                                    )
                                }

                                onlyWhen { error().isNotBlank() } - warning - col {
                                    subtext {
                                        ::content { error() }
                                    }
                                }
                                actionOrCancelSection(
                                    onCancel = {
                                        selected set null
                                        error set ""
                                        close()
                                    },
                                    actionButton = {
                                        importantButton("Set") {
                                            val start = selected.value?.customStart
                                            val end = selected.value?.customEnd

                                            if (start == null || end == null) {
                                                error set "Please select both a start and end date"
                                                return@importantButton
                                            }
                                            if (start > end) {
                                                error set "Start date must be before the end date"
                                                return@importantButton
                                            }
                                            selected set selected.value?.copy(type = DateOptions.Custom)
                                            error set ""
                                            close()
                                        }
                                    }
                                )
                                space()
                            }
                        }
                    }
                }
            }
        }
    }
    col {

        ::exists { selected()?.customStart !== null && selected()?.customEnd !== null }

        space(); space()
        subtext("Custom Date Range")


        row {
            localDateField {
                content bind selected.lens(
                    get = { it?.customStart },
                    modify = { old, value -> old?.copy(customStart = value) }
                )
            }
            expanding - space()
            bold - subtext("to")
            expanding - space()
            localDateField {
                content bind selected.lens(
                    get = { it?.customEnd },
                    modify = { old, value -> old?.copy(customEnd = value) }
                )
            }
        }
    }
}


fun ViewWriter.guestFilter(filterGuests: Property<List<CollectionUserData>>) = expanding - col {
    val sharees = Property<List<CollectionUserData>>(emptyList())
    val loading = Property(false)
    launch {
        loading set true
        val coll = session.awaitNotNull().collection2.getCollectionLive(currentCollection()!!.collectionId)

        session.awaitNotNull().collection2.listUsers(currentCollection()!!.collectionId).let {

            sharees set (it.users.map {
                if (it.userId == coll().collection.creatorUserId) it.copy(name = "Me") else it
            }).sortedByDescending { if (it.name == "Me") 100 else it.participationRights.value }
        }
        loading set false
    }

    padded - col {
        ::exists { loading() }
        space(); space();space()
        centered - activityIndicator()
    }

    padded - col {
        ::exists { !loading() && sharees().isEmpty() }
        centered - text("No one has contributed to this collection yet")
    }
    expanding - recyclerView {
        ::exists { !loading() && sharees().isNotEmpty() }
        children(sharees) { guest ->
            col {
                stack {
                    atStart - row {
                        centered - outlinedButton - ThemeDerivation {
                            it.copy(cornerRadii = CornerRadii.RatioOfSize(0.25f)).withBack
                        }.onNext - checkbox {
                            checked bind shared {
                                filterGuests().contains(guest())
                            }.withWrite {
                                launch {
                                    if (it) filterGuests set filterGuests().plusElement(guest())
                                    else filterGuests set filterGuests().minusElement(guest())
                                }
                            }
                        }
                        guestIconAndName(guest)
                    }
                }
            }
        }
    }
}