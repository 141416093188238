package com.picme.sdk2.caching

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.ImageLocal
import com.lightningkite.kiteui.models.ImageRaw
import com.lightningkite.kiteui.models.ImageSource
import com.lightningkite.kiteui.reactive.LateInitProperty
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.ReadableState
import com.lightningkite.kiteui.reactive.invoke
import com.picme.Resources
import com.picme.ownsCollection
import com.picme.sdk2.generated.*
import com.picme.sdk2.generated.collection2.*
import kotlinx.coroutines.delay
import kotlinx.datetime.Clock
import kotlin.time.Duration
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds


class CollectionHandler2ApiCacheableLive2(
    val basedOn: CollectionHandler2Api,
    val clock: Clock = Clock.System,
    val delay: suspend (Duration) -> Unit = { kotlinx.coroutines.delay(it) },
    val uploadFile: suspend (String, RequestBody, onProgress: (Double) -> Unit) -> Unit = { uri, body, onProgress ->
        fetch(uri, HttpMethod.PUT, body = body, onUploadProgress = { a, b ->
            onProgress(a.toDouble() / b)
        })
    },
    val userId: () -> UserId,
    val externalStorage: ExternalStorage = ExternalStorage.PlatformCache(70000),
    val cacheLife: Duration
) : CollectionHandler2ApiCacheable,
    CollectionHandler2Api by basedOn {

    // Collections

    private val collections = object : PagedHelperCache<ListedCollection, CollectionId>(
        clock,
        externalStorage.sub("collections"),
        ListedCollection.serializer(),
        cacheLife
    ) {
        init {
            requireIndexLoaded = 99999
        }

        override val comparator: Comparator<ListedCollection>
            get() = compareByDescending<ListedCollection> {
                ownsCollection(it, userId())
            }.thenBy { it.collection.name.lowercase() }.thenByDescending {
                it.userParticipationRights.value // For duplicates with different permissions.
            }

        override fun id(t: ListedCollection): CollectionId = t.collection.collectionId

        override suspend fun next(token: String?): Response<ListedCollection> =
            try {
                basedOn.listCollections(
                    filters = null, itemsPerPage = 100, continuation = token, forUserId = null,
                )
            } catch (e: Exception) {
                ListCollectionsResponse2(listOf(), null) // Probably because has not read tos
            }.let {
                Response(it.continuation, it.collections)
            }

        override suspend fun detail(id: CollectionId): ListedCollection = basedOn.getCollection(id).let {
            ListedCollection(
                collection = it.collection,
                getCoverPhotoUri = it.getCoverPhotoUri,
                userRights = basedOn.getCollectionRights(it.collection.collectionId, targetUserId = null).rights
            )
        }

        init {
            startup()
        }
    }

    override suspend fun createCollection(body: CreateCollectionBody): CreateCollectionResponse2 =
        basedOn.createCollection(body).also {
            collections.update(
                it.collection.collectionId,
                ListedCollection(it.collection, userRights = Rights.fromRights(setOf(RightsEnum.Everything)))
            )
        }


    override suspend fun listInviteCodes(
        linkRelationshipType: LinkRelationshipType?,
        linkPrimaryGlobalId: RecordGlobalId?
    ): ListInviteCodesResponse {
        return InviteCache.listInvites(linkPrimaryGlobalId) ?: basedOn.listInviteCodes(
            linkRelationshipType,
            linkPrimaryGlobalId
        ).also {
            InviteCache.setColl(linkPrimaryGlobalId, it.inviteCodes)
        }
    }

    override suspend fun createRequestInviteCode(
        collectionGlobalId: RecordGlobalId,
        name: String,
        clientInformation: String
    ): CreateInviteCodeResponse {
        return basedOn.createRequestInviteCode(collectionGlobalId, name, clientInformation)
            .also { InviteCache.invalidateAll() }
    }

    override suspend fun createSharingInviteCode(
        collectionGlobalId: RecordGlobalId,
        name: String,
        clientInformation: String,
        rightsToOtherUsersUploads: Rights
    ): CreateInviteCodeResponse {
        return basedOn.createSharingInviteCode(collectionGlobalId, name, clientInformation, rightsToOtherUsersUploads)
            .also {
                InviteCache.invalidateAll()
            }
    }

    override suspend fun patchInviteCode(inviteId: InviteCodeId, body: PatchInviteCodeBody): CreateInviteCodeResponse {
        return basedOn.patchInviteCode(inviteId, body).also {
            InviteCache.patchInviteCode(inviteId, body)
        }
    }

    override suspend fun patchCollection(
        collectionId: CollectionId,
        body: PatchCollectionBody
    ): PatchCollectionResponse2 = basedOn.patchCollection(collectionId, body).also { r ->
        collections.update(collectionId) { it.copy(collection = r.collection) }
    }

    override suspend fun putCollectionCoverPhoto(
        collectionId: CollectionId,
        body: RequestBody,
        tempUri: String?,
        onProgress: (Double) -> Unit
    ) {
        val oldUri = basedOn.getCollection(collectionId).getCoverPhotoUri
        val t = basedOn.getCollectionModificationStamp(collectionId)
        val r = basedOn.putCollectionCoverPhoto(collectionId, body.type)
        uploadFile(r.putCoverPhotoUri, body, onProgress)
        if (tempUri != null) {
            collections.update(collectionId) {
                it.copy(getCoverPhotoUri = tempUri)
            }
        }
        launchGlobal {
            var count = 0
            while (count++ < 100) {
                delay(1.seconds)
                if (oldUri != basedOn.getCollection(collectionId).getCoverPhotoUri) break;
            }
            delay(5000) // For a couple of seconds, the photo still isn't there
            val uri = basedOn.getCollection(collectionId).getCoverPhotoUri
            collections.update(collectionId) {
                it.copy(getCoverPhotoUri = uri)
            }
        }
    }

    override suspend fun deleteCollectionCoverPhoto(collectionId: CollectionId, photoId: String) =
        basedOn.deleteCollectionCoverPhoto(collectionId, photoId).also {
            collections.update(collectionId) { it.copy(getCoverPhotoUri = "") }
        }

    override suspend fun deleteCollection(collectionId: CollectionId): DeleteCollectionResponse2 =
        basedOn.deleteCollection(collectionId).also {
            collections.update(collectionId, null)
        }

    override suspend fun revokeRights(collectionId: CollectionId, userId: UserId): RevokeRightsResponse2 {
        return basedOn.revokeRights(collectionId, userId).also {
            val coll = collections.all().first {
                it.collection.collectionId == collectionId
            }
            if (coll.userRights != Rights.fromRights(setOf(RightsEnum.Everything))) {
                collections.update(collectionId, null)
            }
        }
    }

    override fun getCollectionLive(collectionId: CollectionId): Readable<ListedCollection> {
        return collections.single(collectionId)
    }

    override fun listCollectionsLive(): Paged<ListedCollection> {
        collections.quietRefreshIfNeeded()
        return collections
    }


    // Uploads

    inner class UploadReadable(val collectionId: CollectionId, val uploadId: UploadId) : Readable<GetUploadResponse2> {
        val underlying = LateInitProperty<GetUploadResponse2>()
        var cacheLife = 5.minutes
        var probablyDeleted = false
        private var lastRepull = clock.now()
        fun quietRefreshIfNeeded() {
            if (clock.now() - lastRepull > cacheLife) {
                launchGlobal { quietRefresh() }
            }
        }

        private suspend fun quietRefresh() {
            lastRepull = clock.now()
            val newValue = if (probablyDeleted)
                try {
                    basedOn.getDeletedUpload(collectionId, uploadId).let { // TODO: Get uploader info
                        GetUploadResponse2(it.upload, UploaderInfo(), it.getThumbnailUri, it.getDetailsUri)
                    }
                } catch (e: Exception) {
                    basedOn.getUpload(collectionId, uploadId).also {
                        probablyDeleted = false
                    }
                }
            else
                try {
                    basedOn.getUpload(collectionId, uploadId)
                } catch (e: Exception) {
                    basedOn.getDeletedUpload(collectionId, uploadId).also {
                        probablyDeleted = true
                    }.let {// TODO: Get uploader info
                        GetUploadResponse2(it.upload, UploaderInfo(), it.getThumbnailUri, it.getDetailsUri)
                    }
                }
            underlying.value = newValue
        }

        fun forceRefresh() {
            underlying.unset()
            launchGlobal {
                quietRefresh()
            }
        }

        fun update(item: GetUploadResponse2) {
            underlying.value = item
            lastRepull = clock.now()
        }

        fun update(item: (GetUploadResponse2) -> GetUploadResponse2) {
            underlying.state.onSuccess {
                underlying.value = item(it)
                lastRepull = clock.now()
            }
        }

        override val state: ReadableState<GetUploadResponse2> get() = underlying.state
        override fun addListener(listener: () -> Unit): () -> Unit = underlying.addListener(listener)

        init {
            launchGlobal { quietRefresh() }
        }
    }

    private val uploadDetails = HashMap<UploadId, UploadReadable>()
    private val uploadsDeleted = HashMap<CollectionId, PagedHelperCache<ListedUpload, UploadId>>()
    private val uploads = HashMap<CollectionId, PagedHelperCache<ListedUpload, UploadId>>()

    fun removeEntireCache() {
        this.externalStorage.clear()
    }

    suspend fun forceRefresh() {
        uploadDetails.values.forEach { it.forceRefresh() }
        uploadsDeleted.values.forEach { it.forceRefresh() }
        uploads.values.forEach { it.forceRefresh() }
        collections.all().forEach {
            this.externalStorage.remove("uploads.${it.collection.collectionId.raw}.cache")
        }
        collections.forceRefresh()
    }

    private var recent: String? = null

    suspend fun checkRefreshCollImages(collectionId: CollectionId) {
        val changed = basedOn.getCollectionModificationStamp(collectionId).modificationStamp.toString()
        if (recent == null) recent = changed

        if (recent != changed) {
            recent = changed
            uploads[collectionId]?.forceRefresh()
        }

        uploads.forEach {
            it.value.quietRefreshIfNeeded()
        }
    }

    override fun getUploadLive(collectionId: CollectionId, uploadId: UploadId): Readable<GetUploadResponse2> =
        uploadDetails.getOrPut(uploadId) {
            UploadReadable(collectionId, uploadId)
        }.also { it.quietRefreshIfNeeded() }

    override fun getDeletedUploadLive(collectionId: CollectionId, uploadId: UploadId): Readable<GetUploadResponse2> =
        uploadDetails.getOrPut(uploadId) {
            UploadReadable(collectionId, uploadId)
        }.also { it.quietRefreshIfNeeded() }

    override fun listDeletedUploadsLive(collectionId: CollectionId): Paged<ListedUpload> =
        uploadsDeleted.getOrPut(collectionId) {
            object :
                PagedHelperCache<ListedUpload, UploadId>(
                    clock,
                    ExternalStorage.Test(),
                    ListedUpload.serializer(),
                    cacheLife
                ) {
                override val comparator: Comparator<ListedUpload> = compareBy { it.uploadId.raw }
                override fun id(t: ListedUpload): UploadId = t.uploadId
                override suspend fun next(token: String?): Response<ListedUpload> =
                    basedOn.listDeletedUploads(collectionId, token ?: "", 100).let {
                        Response(it.continuation, it.uploads)
                    }

                init {
                    startup()
                }
            }
        }

    override fun listUploadsLive(collectionId: CollectionId): Paged<ListedUpload> = uploads.getOrPut(collectionId) {
        object : PagedHelperCache<ListedUpload, UploadId>(
            clock,
            externalStorage.sub("uploads.${collectionId.raw}"),
            ListedUpload.serializer(),
            cacheLife
        ) {
            override val comparator: Comparator<ListedUpload> = compareBy { it.uploadId.raw }
            override fun id(t: ListedUpload): UploadId = t.uploadId
            override suspend fun next(token: String?): Response<ListedUpload> =
                /* a null continuation is equivalent to 0. */
                basedOn.listUploads(collectionId, UploadQuery(), token ?: "", 100).let {
                    Response(it.continuation, it.uploads)
                }

            init {
                startup()
            }
        }
    }

    override suspend fun deleteAllUploads(collectionId: CollectionId): DeleteAllUploadsResponse2 =
        basedOn.deleteAllUploads(collectionId).also {
            uploads[collectionId]?.all?.state?.onSuccess {
                uploadsDeleted[collectionId]?.updateInsertMany(it)
            } ?: uploadsDeleted[collectionId]?.quietRefresh()
            uploads[collectionId]?.updateClear()
        }

    override suspend fun copyUpload(
        caption: String?,
        sourceCollectionId: CollectionId,
        sourceUploadId: UploadId,
        destinationCollectionId: CollectionId,
        anonymous: Boolean,
        allowDuplicates: Boolean
    ): CopyUploadResponse2 =
        basedOn.copyUpload(
            caption,
            sourceCollectionId,
            sourceUploadId,
            destinationCollectionId,
            anonymous,
            allowDuplicates
        ).also {
            uploads[destinationCollectionId]?.update(
                it.destinationUpload.uploadId,
                ListedUpload(
                    it.destinationUpload.uploadId,
                    it.destinationUpload.mimeType,
                    it.getDestinationThumbnailUri
                )
            )
        }

    override suspend fun patchUpload(
        collectionId: CollectionId,
        uploadId: UploadId,
        body: PatchUploadBody
    ): PatchUploadResponse2 = basedOn.patchUpload(collectionId, uploadId, body).also {
        uploadDetails.getOrPut(it.upload.uploadId) { UploadReadable(collectionId, uploadId) }.update { e ->
            e.copy(upload = it.upload)
        }
    }

    override suspend fun deleteUpload(collectionId: CollectionId, uploadId: UploadId): DeleteUploadResponse2 =
        basedOn.deleteUpload(collectionId, uploadId).also {
            uploadDetails.getOrPut(it.uploadId) { UploadReadable(collectionId, uploadId) }.probablyDeleted = true
            uploads[it.collectionId]?.all?.state?.onSuccess { k ->
                k.find { it.uploadId == uploadId }?.let { d ->
                    uploadsDeleted[it.collectionId]?.update(it.uploadId, d)
                } ?: uploadsDeleted[it.collectionId]?.quietRefresh()
            }
            uploads[it.collectionId]?.update(it.uploadId, null)
        }

    override suspend fun restoreDeletedUploads(
        collectionId: CollectionId,
        uploadIds: List<UploadId>?
    ): RestoreDeletedUploadsResponse2 = basedOn.restoreDeletedUploads(collectionId, uploadIds).also {
        if (uploadIds != null) {
            uploadIds.forEach {
                uploadDetails.getOrPut(it) { UploadReadable(collectionId, it) }.probablyDeleted = false
                uploadsDeleted[collectionId]?.all?.state?.onSuccess { k ->
                    k.find { u -> u.uploadId == it }?.let { d ->
                        uploads[collectionId]?.update(it, d)
                    } ?: uploads[collectionId]?.quietRefresh()
                }
                uploadsDeleted[collectionId]?.update(it, null)
            }
        } else {
            uploadsDeleted[collectionId]?.all?.state?.onSuccess {
                uploads[collectionId]?.updateInsertMany(it)
            } ?: uploads[collectionId]?.quietRefresh()
            uploadsDeleted[collectionId]?.updateClear()
        }
    }

    override suspend fun createUpload(
        collectionId: CollectionId,
        anonymous: Boolean,
        caption: String?,
        data: RequestBody,
        hachCode: String
    ): ContinueUpload {
        try {
            val r = basedOn.createUpload(
                collectionId = collectionId,
                body = CreateUploadBody(
                    filename = if (data is RequestBodyFile) data.content.fileName() else "file",
                    contentType = MimeType(data.type),
                    bytes = data.bytes,
                    identifiedHash = hachCode,
                    caption = caption,
                    anonymous = anonymous
                ),
                allowDuplicates = false
            )
            if (data.type.contains("video")) {
                uploadToLocal[r.upload.uploadId] = Resources.videoPlaceholder
            } else {
                when (data) {
                    is RequestBodyBlob -> uploadToLocal[r.upload.uploadId] = ImageRaw(data.content)
                    is RequestBodyFile -> uploadToLocal[r.upload.uploadId] = ImageLocal(data.content)
                    is RequestBodyText -> {}
                }
            }
            return object : ContinueUpload {
                override suspend fun invoke(onProgress: (Double) -> Unit) {
                    uploadFile(r.putOriginalUploadUri, data, onProgress)
                    uploads[collectionId]?.update(
                        r.upload.uploadId,
                        ListedUpload(r.upload.uploadId, r.upload.mimeType, r.getThumbnailUri)
                    )
                    uploadDetails.getOrPut(r.upload.uploadId) { UploadReadable(collectionId, r.upload.uploadId) }
                        .update(GetUploadResponse2(r.upload, UploaderInfo(), r.getThumbnailUri, ""))
                }
            }
        } catch (e: Exception) {
            // Most likely "Already Exists"- two uploads with the same identifiedHash
            println("Error when creating upload")
            return object : ContinueUpload {
                override suspend fun invoke(onProgress: (Double) -> Unit) {
//                    throw Exception("Error when creating upload", e)
                }
            }
        }
    }
}

val uploadToLocal = HashMap<UploadId, ImageSource>()