package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*


fun ViewWriter.authScreen(
    title: String,
    backArrow: Readable<Boolean> = Constant(false),
    innerContent: ContainingView.() -> Unit
) {

    unpadded - scrolls - col {
        dynamicTheme { if (isSmallScreen()) null else grayBack }
        expanding - space { ::exists { !isSmallScreen() } }
        sizeConstraints(minHeight = 1.5.rem) - space {
            ::exists { isSmallScreen() }
        }

        centered - sizeConstraints(width = 28.rem) - padded - stack {
            dynamicTheme {
                if (isSmallScreen()) null
                else FatCardSemantic
            }
            col {
                spacing = 1.rem
                picmeIconDisplay()
                stack {
                    iconButtonTheme - atTopStart - button {
                        smallIcon(PIcon.chevronleft)
                        ::exists{ backArrow() }
                        onClick {
                            screenNavigator.goBack()
                        }
                    }
                    centered - h3 {
                        content = title
                    }
                }
                innerContent()
            }
        }
        space {
            ::exists { isSmallScreen() }
        } in sizeConstraints(minHeight = 4.rem)
        expanding - space { ::exists { !isSmallScreen() } }
    }
}


fun ViewWriter.picmeIconDisplay(size: Dimension = 48.dp) {
    stack {
        spacing = 0.rem
        centered - sizeConstraints(width = size, height = size) - image {
            source = Vectors.logoWithWords
        }
    }
}