package com.picme.views

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.eula
import com.picme.installrefer.InstallReferStartScreen

@Routable("welcome")
object WelcomeTOSLanding : Screen, UnauthScreen {

    override val title = Constant("")

    val acknowledged = PersistentProperty("tos-launch-flag", false)

    override fun ViewWriter.render() {
        themedBg - stack {
            stack {
                col {
                    centered - sizeConstraints(width = 20.rem, height = 20.rem) - image {
                        source = Resources.picmePaige
                    }
                    space()

                    centered - FadedForeground.onNext - h3("Welcome to PicMe")
                    centered - FadedForeground.onNext - eula("By clicking Continue, you agree to our")

                    centered - sizeConstraints(width = 28.rem, height = 42.dp) - buttonTheme - important - button {
                        centered - row {
                            centered - h6("Continue")
                            onClick {
                                acknowledged.value = true
                                screenNavigator.replace(CollectionLanding)
                                if (Platform.current == Platform.iOS) {
                                    val installReferStartPath = screenNavigator.routes.render(InstallReferStartScreen)?.urlLikePath?.render()
                                    ExternalServices.openTab("${frontendUrl()}/$installReferStartPath")
                                }
                            }
                        }
                    }
                    space(); space()
                    getTheAppCard("collection")
                }
            }
        }
    }
}