package com.picme.views.share

import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.important
import com.picme.PIcon
import com.picme.components.*
import com.picme.iconButtonTheme
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.CollectionUserData

enum class GuestSubView { Guests, GuestDetail }
class GuestView(val collectionId: CollectionId, val onBack: suspend () -> Unit) : PicmeDialog() {
    val guest = Property<CollectionUserData?>(null)

    override fun ViewWriter.topBar(close: suspend () -> Unit) =col {
        spacing = 4.dp
        row {
            iconButtonTheme - button {
                smallIcon(PIcon.chevronleft)
                onClick{
                   if (guest() != null) {
                        guest set null
                    } else {
                        onBack()
                    }
                }
            }
            centered - h3 { ::content {
                if (guest() != null) "Guest Permissions"
                else "Guests"
            } }
            expanding - space {}
        }
    }

    override val withinCollContext = true
    override fun ViewWriter.content() {

        expanding - col {
            expanding - swapView {
                swapping(
                    current = {
                        if (guest() != null) GuestSubView.GuestDetail
                        else GuestSubView.Guests
                    },
                    views = { currTab ->
                        when (currTab) {
                            GuestSubView.Guests -> with(Guests(collectionId) {
                                guest set it
                            }) { render() }

                            GuestSubView.GuestDetail -> with(GuestDetail(
                                guest = shared { guest.awaitNotNull() },
                                collectionId = collectionId,
                                onBack = { guest set null }
                            )) { render() }
                        }
                    }
                )
            }
        }
    }

}