package com.picme.components

import com.lightningkite.kiteui.models.CornerRadius
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.div
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.reactive
import com.lightningkite.kiteui.views.ViewDsl
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.tweakTheme
import com.picme.PIcon
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract


class PopoverSetup(
    val icon: IconView,
    val button: Button,
    val text: TextView
) {
    var exists by button::exists
}


fun ViewWriter.popoverItemActual(setup: PopoverSetup.() -> Unit) {
    val icon: IconView
    val button: Button
    val textView: TextView

    popoverItemTheme - button {
        button = this
        row {
            sizeConstraints(minWidth = 1.5.rem, minHeight = 1.5.rem) - centered - icon {
                icon = this
                reactive {
                    source = PIcon.expand
                }
            }
            space {}
            centered - h6 {
                textView = this
            }
        }
    }
    setup(PopoverSetup(icon, button, textView))
}

@ViewDsl
@OptIn(ExperimentalContracts::class)
inline fun ViewWriter.popoverItem(noinline setup: PopoverSetup.() -> Unit) {
    contract { callsInPlace(setup, InvocationKind.EXACTLY_ONCE) }; popoverItemActual(setup)
}

@ViewDsl
val ViewWriter.popoverItemTheme
    get() = ThemeDerivation {
        it.copy(spacing = it.spacing / 2, cornerRadii = CornerRadius.Constant(0.5.rem)).withoutBack
    }.onNext
