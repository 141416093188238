package com.picme.components

import com.lightningkite.kiteui.models.PopoverPreferredDirection
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.closePopovers
import com.lightningkite.kiteui.views.direct.button
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.h5
import com.lightningkite.kiteui.views.direct.icon
import com.lightningkite.kiteui.views.direct.image
import com.lightningkite.kiteui.views.direct.menuButton
import com.lightningkite.kiteui.views.direct.onClick
import com.lightningkite.kiteui.views.direct.row
import com.lightningkite.kiteui.views.direct.sizeConstraints
import com.lightningkite.kiteui.views.direct.stack
import com.picme.PIcon
import com.picme.Vectors
import com.picme.currentCollection
import com.picme.gravityCentered
import com.picme.sdk2.generated.CollectionId
import com.picme.uploadExternalFiles
import com.picme.uploadPicFromCamera

fun ViewWriter.addImageActions(preferredPopoverDirection: PopoverPreferredDirection) {
    centered - menuButton {
        spacing = 5.dp
        centered - sizeConstraints(minWidth = 2.8.rem, minHeight = 2.8.rem) - image {
            source = Vectors.logoWithAdd
        }
        preferredDirection = preferredPopoverDirection
        requireClick = true

        opensMenu {
            popoverWrapper(width = 20.rem) {
                imageOptionsMenu()
            }
        }
    }
}

fun ViewWriter.imageOptionsMenu() {
    val collectionId = shared { currentCollection()?.collectionId ?: CollectionId("") }

    sizeConstraints(width = 250.dp) - stack { col {
        row {
            gravityCentered - icon { source = PIcon.image; description = "Choose image from photo library"}
            button {
                onClick {
                    uploadExternalFiles(collectionId())
                    closePopovers()
                }
                h5 { content = "Photo Library" }
            }
        }
        row {
            gravityCentered - icon { source = PIcon.camera; description = "Take picture or video with camera" }
            button {
                onClick {
                    uploadPicFromCamera(collectionId())
                    closePopovers()
                }
                h5 { content = "Take Photo" }
            }
        }
    }}
}