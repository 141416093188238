package com.picme.views

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.PCollection

class ViewTrash(val collectionId: CollectionId, val onBack: suspend () -> Unit) : PicmeDialog() {
    override val title = Constant("Trash")
    override val alwaysLarge = true
    override val withinCollContext = true

    override fun ViewWriter.topBar(close: suspend () -> Unit) = col {
        row {
            centered - actionButtonNoText(displayIcon = PIcon.chevronleft) { onBack() }
            centered - h3 {
                ::content { title() }
            }
            expanding - space()
            FadedForeground.onNext - centered - subtext("Items expire after 30 days")
        }
    }

    override fun ViewWriter.content() {
        expanding - unpadded - stack {
            spacing = 0.dp
            val collection = shared { session.awaitNotNull().collection2.getCollectionLive(collectionId)().collection }
            val trashImages = shared {
                session.awaitNotNull().collection2.listDeletedUploadsLive(collectionId).all()
            }

            centered - activityIndicator {
                reactiveScope {
                    exists = true
                    trashImages()
                    exists = false
                }
            }
            centered - FadedForeground.onNext - text {
                exists = false
                ::exists { trashImages().isEmpty() }
                content = "Trash is empty"
            }
            expanding - unpadded - col {
                spacing = 0.dp
                exists = false
                ::exists { trashImages().isNotEmpty() }


                selectItemsBar(CollectionState.selectTrash)

                expanding - imageRecyclerView(
                    colImages = shared { trashImages().toRecyclableInfo() },
                    selectedImages = CollectionState.selectTrash,
                    setup = {
                        reactiveSuspending {
                            collection().trashedImages().requireIndexLoaded = lastVisibleIndex() + 50
                        }
                    }
                )
            }
        }
    }
}

fun ViewWriter.trashActions(bottom: Boolean) {
    stack {
        val collection = currentCollection
        val weAreOwner = sharedSuspending { ownsPCollection(collection()) }
        val trashImages = shared {
            collection()?.collectionId?.let {
                session.awaitNotNull().collection2.listDeletedUploadsLive(it).all()
            } ?: listOf()
        }
        ifElse(
            condition = { CollectionState.selectTrash.isSelecting() },
            ifTrue = {
                row {
                    imageSelectedCounter(CollectionState.selectTrash)
                    space { exists = bottom } in weight(1f)

                    space() { exists = !bottom }

                    actionButtonNoText(
                        exists = weAreOwner,
                        displayIcon = PIcon.restore,
                        enabled = shared { CollectionState.selectTrash.selected().isNotEmpty() },
                    ) {
                        showConfirmDialog(
                            title = "Confirm Restore",
                            content = "Selected items will be restored to the collection.",
                            confirmLabel = "Restore",
                            onConfirm = {
                                val images = trashImages().filter {
                                    it.uploadId.raw in CollectionState.selectTrash.selected().map { it.id }
                                }
                                CollectionState.selectTrash.stopSelecting()
                                restoreImages(images)
                            }
                        )
                    }

                    actionButtonNoText(
                        exists = weAreOwner,
                        displayIcon = PIcon.trash,
                        enabled = shared { CollectionState.selectTrash.selected().isNotEmpty() }
                    ) {
                        showConfirmDialog(
                            title = "Confirm Delete",
                            content = "Selected items will be permanently deleted.",
                            confirmLabel = "Delete",
                            warning = "This action cannot be undone.",
                            onConfirm = {
                                val images = trashImages().filter {
                                    it.uploadId.raw in CollectionState.selectTrash.selected().map { it.id }
                                }
                                CollectionState.selectTrash.stopSelecting()
                                deleteImages(images)
                            }
                        )
                    }
                }
            },
            ifFalse = {
                row {
                    space() { exists = !bottom }
                    actionButtonNoText(PIcon.select) {
                        CollectionState.selectTrash.startSelecting()
                    }
                    expanding - space { exists = bottom }
                    centered - iconButtonTheme - menuButton {
                        requireClick = true
                        centered - icon(PIcon.more, "More Horiz")
                        preferredDirection = if (bottom) PopoverPreferredDirection.aboveLeft
                        else PopoverPreferredDirection.belowLeft

                        opensMenu {
                            popoverWrapper(width = 20.rem) {
                                col {
                                    toggleGridSizePopover()

                                    popoverItem {
                                        icon.source = PIcon.restore
                                        text.content = "Restore all items"
                                        button.onClick {
                                            closePopovers()
                                            showConfirmDialog(
                                                title = "Confirm Restore",
                                                content = "All items will be restored to the collection.",
                                                confirmLabel = "Restore",
                                                onConfirm = {
                                                    val collectionId =
                                                        collection()?.collectionId ?: return@showConfirmDialog
                                                    restoreImages(
                                                        session.awaitNotNull().collection2.listDeletedUploadsLive(
                                                            collectionId
                                                        ).all()
                                                    )
                                                }
                                            )
                                        }
                                    }

                                    popoverItem {
                                        icon.source = PIcon.trash
                                        text.content = "Delete all items"
                                        button.onClick {
                                            closePopovers()
                                            showConfirmDialog(
                                                title = "Confirm Delete",
                                                content = "All items will be permanently deleted.",
                                                confirmLabel = "Delete",
                                                warning = "This action cannot be undone.",
                                                onConfirm = {
                                                    val collectionId =
                                                        collection()?.collectionId ?: return@showConfirmDialog
                                                    deleteImages(
                                                        session.awaitNotNull().collection2.listDeletedUploadsLive(
                                                            collectionId
                                                        ).all(),
                                                        all = true
                                                    )
                                                }
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        )
    }
}

fun ViewWriter.imageSelectedCounter(images: SelectItems) {
    stack {
        reactiveScope {
            images.stopSelecting()
            currentCollection()
        }
        actionButtonNoText(PIcon.close, exists = shared { images.isSelecting() && images.selected().isEmpty() }) {
            images.stopSelecting()
        }
        outlinedButton - button {
            ::exists { images.selected().isNotEmpty() }
            row {
                spacing = 0.rem
                centered - icon {
                    source = PIcon.close
                }
                expanding - space {}
                centered - h6 {
                    ::content { images.selected().size.toString() }
                }
                expanding - space { }
            }
            onClick { images.stopSelecting() }
        }
    }
}


fun ViewWriter.selectItemsBar(select: SelectItems) {
    onlyWhen { select.isSelecting() } - collectionBanner.onNext - row {
        spacing = 6.dp
        space()
        centered - bold - text("Select one or more items")
        expanding - space()
        iconButtonTheme - unpadded - button {
            unpadded - icon {
                source = PIcon.close
            }
            onClick { select.stopSelecting() }
        }
        space()
    }
}
