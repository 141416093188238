package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.UnauthScreen
import com.picme.components.authScreen
import com.picme.components.importantButton
import com.picme.components.styledPassword
import com.picme.components.styledTextField
import com.picme.emailOrPhone
import com.picme.FadedForeground
import com.picme.isValidEmailOrPhone
import com.picme.sdk2.unauthApi


@Routable("reset-password")
object ResetPassword : Screen, UnauthScreen {
    override fun ViewWriter.render() {
        val showErrorText = Property(false)
        authScreen("Forgot Password", Constant(true)) {

            FadedForeground.onNext - subtext("Enter the email address associated with your account and we'll send you a new verification code that will allow you to reset your password.")

            onlyWhen(false) { showErrorText.await() } - danger - text {
                content = "Invalid email. Check your email and try again."
                align = Align.Center
            }

            styledTextField {
                label = "Email or Phone"
                hint = "Email or Phone"
                content bind emailOrPhone
                field.keyboardHints = KeyboardHints.email
            }

            buttonTheme - important - button {
                centered - h6("Send Verification Code")
                onClick {
                    showErrorText.value = false
                    try {
                        unauthApi().authenticationHandler.requestPasswordReset(emailOrPhone().lowercase())
//                        unauthApi()userAuth.checkUserExists(emailOrPhone.await())
                        navigator.navigate(ConfirmResetPassword)
                    } catch (e: Exception) {
                        showErrorText.value = true
                    }
                }
                ::enabled { emailOrPhone().isValidEmailOrPhone() }
            }
        }
    }
}


@Routable("confirm-reset-password")
object ConfirmResetPassword : Screen, UnauthScreen {
    override fun ViewWriter.render() {
        val verificationCode = Property("")
        val newPassword = Property("")
        val errorText = Property("")
        val passwordError = Property<String?>(null)
        authScreen("Forgot Password", Constant(true)) {
            FadedForeground.onNext - subtext("Enter the verification code sent to your email and the new password for your account.")

            onlyWhen(false) { errorText().isNotEmpty() } - danger - text {
                ::content{ errorText.await() }
                align = Align.Center
            }

            styledTextField {
                label = "Verification Code"
                content bind verificationCode
            }

            val tf: TextField
            styledPassword {
                tf = this.field
                content bind newPassword
                label = "New Password"
                hint = "New Password"
                error bind passwordError
            }

            importantButton(
                text = "Reset Password",
                enabled = shared { verificationCode.await().isNotEmpty() },
                field = tf
            ) {
                passwordError set null
                errorText set ""
                if (newPassword.await().length < 8) {
                    passwordError set "Password must be at least 8 characters long"
                    return@importantButton
                }
                try {
                    unauthApi().authenticationHandler.continuePasswordReset(
                        emailOrPhoneNumber = emailOrPhone().lowercase(),
                        confirmationCode = verificationCode(),
                        newPassword = newPassword()
                    )
                    screenNavigator.reset(LogInScreen)
                } catch (e: Exception) {
                    errorText set "An error has occurred. Make sure your verification code is correct and try again"
                }
            }
        }
    }
}
