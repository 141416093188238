package com.picme

import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.printStackTrace2
import com.lightningkite.kiteui.reactive.awaitNotNull
import com.picme.sdk2.GenerateQr
import com.picme.sdk2.QrType
import com.picme.sdk2.generated.InviteCode
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.sdk2.generated.collection2.Rights
import com.picme.sdk2.generated.collection2.RightsEnum
import com.picme.sdk2.json
import com.picme.sdk2.toSafeEncoded
import com.picme.views.frontendUrl
import com.picme.views.share.ViewInviteInfo
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

suspend fun getQrCodesForCollection(collection: PCollection): List<ViewInviteInfo> {
    val qrs = session.awaitNotNull().collection2.listInviteCodes(
        linkRelationshipType = null,
        linkPrimaryGlobalId = collection.collectionGlobalId
    ).inviteCodes.filter { it.clientInfo()?.collectionId == collection.collectionId.raw }.let { codes ->
        if (codes.size > 1) codes
        else {
            listOf(
                codes.find { it.clientInfo()?.type == InviteType.ShareColl } ?: createShareQr(collection),
                codes.find { it.clientInfo()?.type == InviteType.RequestUploads }
                    ?: createRequestUploadsQr(collection),
            )
        }
    }

    return qrs.sortedBy { it.clientInfo()?.type }.map { qr ->
        val perms = qr.clientInfo()?.type ?: InviteType.ShareColl
        ViewInviteInfo(
            inviteCode = qr,
            permission = perms,
            link = "${frontendUrl()}/${qr.sharePath()}"
        )
    }.distinctBy { it.inviteCode.clientInfo()?.type }
}


suspend fun createShareQr(collection: PCollection): InviteCode =
    session.awaitNotNull().collection2.createSharingInviteCode(
        name = "Share Collection",
        clientInformation = serializeQrInfo(
            ClientInfo(collection.collectionId.raw, InviteType.ShareColl)
        ),
        rightsToOtherUsersUploads = Rights.fromRights(setOf(RightsEnum.List, RightsEnum.Read)),
        collectionGlobalId = collection.collectionGlobalId
    ).inviteCode.also {
        GenerateQr.getQrCode(hostName = frontendUrl(), path = it.sharePath(), QrType.Png)
    }


suspend fun createRequestUploadsQr(collection: PCollection): InviteCode =
    session.awaitNotNull().collection2.createRequestInviteCode(
        collection.collectionGlobalId,
        name = "Request Photos",
        clientInformation = serializeQrInfo(
            ClientInfo(collection.collectionId.raw, InviteType.RequestUploads)
        ),
    ).inviteCode.also {
        GenerateQr.getQrCode(hostName = frontendUrl(), path = it.sharePath(), QrType.Png)
    }


suspend fun createInitialQrCodes(collection: PCollection) {
    launchGlobal { createShareQr(collection) }
    launchGlobal { createRequestUploadsQr(collection) }
}

fun Rights.rightsOnCollection(): String {
    if (this.value == -1) return "Owner"
    if (this.value <= 2) return "Upload Only"
    if (this.value <= 3) return "Upload & View"
    return "Upload, View, Edit"
}

fun serializeQrInfo(info: ClientInfo): String {
    return json.encodeToString(ClientInfo.serializer(), info)
}

fun InviteCode.clientInfo(): ClientInfo? {
    return try {
        json.decodeFromString<ClientInfo>(this.clientInformation ?: "")
    } catch (e: Exception) {
        e.printStackTrace2()
        null
    }
}

fun InviteCode.sharePath(): String = "in/${this.inviteCodeId.raw.toSafeEncoded()}"

object QrClientTypeSerializer : KSerializer<InviteType> {
    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("QrClientTypeSerializer", PrimitiveKind.STRING)

    override fun deserialize(decoder: Decoder): InviteType =
        InviteType.byValue[decoder.decodeString()] ?: throw Error("Invite type is invalid")

    override fun serialize(encoder: Encoder, value: InviteType) = encoder.encodeString(value.value)

}

@Serializable(QrClientTypeSerializer::class)
enum class InviteType(val value: String) {
    RequestUploads("RequestUploads"),
    ShareColl("ShareColl"),
    Referral("Referral");

    fun readable(): String = when (this) {
        RequestUploads -> "Upload only"
        ShareColl -> "Upload & View"
        Referral -> "Refer a friend"
    }

    companion object {
        val byValue = InviteType.values().associateBy { it.value }
    }
}

@Serializable
data class ClientInfo(
    val collectionId: String? = null,
    val type: InviteType
)
