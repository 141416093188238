package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.reactiveSuspending
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.createCollectionModal

@Routable("collection")
object CollectionLanding : Screen, UnauthScreen {
    override val title = Constant("")
    override fun ViewWriter.render() {
        themedBg - stack {
            col {
                centered - sizeConstraints(width = 20.rem, height = 20.rem) - image {
                    source = Resources.picmePaige
                }
                space()

                centered - FadedForeground.onNext - text("To get started, create a collection")
                centered - sizeConstraints(width = 28.rem, height = 42.dp) - buttonTheme - important - button {
                    centered - row {
                        icon { source = PIcon.add }
                        centered - h6("Create Collection")
                        onClick { createCollectionModal() }
                    }
                }
                centered - sizeConstraints(width = 28.rem) - loginOrSignupButton()
                space(); space()
                getTheAppCard("collection")
            }
        }
    }
}