package com.picme.components

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.applyAlpha
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.FadedForeground
import com.picme.picmeBlue


@ViewModifierDsl3
val ViewWriter.blueLink: ViewWrapper
    get() = padded - compact - tweakTheme {
        it.copy(foreground = Color.picmeBlue, background = it.background.applyAlpha(0f), outlineWidth = 0.dp)
    }

fun ViewWriter.eula(text: String) {
    col {
        spacing = 0.rem
        centered - FadedForeground.onNext - subtext(text)
        compact - centered - row {
            blueLink - externalLink {
                subtext("Terms of Use")
                to = "https://www.picme.com/terms-of-use.html"
                newTab = true
            }
            FadedForeground.onNext - centered - subtext("and")

            blueLink - externalLink {
                subtext("Privacy Policy")
                to = "https://www.picme.com/privacy-policy.html"
                newTab = true
            }
        }
    }
}