package com.picme

import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.picme.components.RecyclableInfo
import com.picme.sdk2.generated.collection2.CollectionUserData
import com.picme.sdk2.generated.collection2.DateTimeRange
import com.picme.sdk2.generated.collection2.UploadQuery
import com.picme.views.CollSubPage
import kotlinx.datetime.*
import kotlinx.datetime.TimeZone.Companion.UTC


object CollectionState {
    private val view = Property(CollSubPage.Main)

    val currView: Readable<CollSubPage> get() = view
    suspend fun setView(v: CollSubPage) {
        view set v
    }

    suspend fun toggleView(v: CollSubPage) {
        view set if (view() == v) CollSubPage.Main else v
    }

    val filterGuests = Property<List<CollectionUserData>>(emptyList())
    val filterDate = Property<DateOptions?>(null)

    val filtersOn: Readable<Boolean> = shared {
        filterGuests().isNotEmpty() || filterDate() != null
    }

    val selectItems = SelectItems()
    val selectTrash = SelectItems()
}

class SelectItems {
    private val items = Property<List<RecyclableInfo>?>(null)

    val isSelecting: Readable<Boolean> = shared { items() != null }
    val selected: Readable<List<RecyclableInfo>> = shared { items() ?: listOf() }

    suspend fun startSelecting() {
        items set listOf()
    }

    fun stopSelecting() {
        items.value = null
    }

    fun addItem(item: RecyclableInfo) {
        items.value = items.value?.plus(item)
    }

    fun removeItem(item: RecyclableInfo) {
        items.value = items.value?.minus(item)
    }
}

enum class DateOptions(private val str: String) {
    Today("Today"),
    Yesterday("Yesterday"),
    ThisWeek("This Week"),
    Last7Days("Last 7 Days"),
    ThisMonth("This Month"),
    Last30Days("Last 30 Days"),
    Custom("Custom");

    override fun toString(): String {
        return str
    }
}


fun CollectionState.filters(): Readable<UploadQuery> {
    return shared {
        UploadQuery(
            uploaderUserId = this@filters.filterGuests().firstOrNull()?.userId,
//            uploadTimeRange = this@filters.filterDate()?.toTimeRange()
        )
    }
}

suspend fun CollectionState.clearFilters() {
    this.filterGuests set emptyList()
    this.filterDate set null
}

fun DateOptions.toTimeRange(): DateTimeRange {
    val now = Clock.System.now()
    val today = now.toLocalDateTime(UTC).date
    val start = when (this) {
        DateOptions.Today -> today.atStartOfDayIn(UTC)
        DateOptions.Yesterday -> {
            val yesterday = today.minus(1, DateTimeUnit.DAY)
            yesterday.atStartOfDayIn(UTC)
        }

        DateOptions.ThisWeek -> {
            val startOfWeek = today.minus(today.dayOfWeek.ordinal.toLong(), DateTimeUnit.DAY)
            startOfWeek.atStartOfDayIn(UTC)
        }

        DateOptions.Last7Days -> {
            val sevenDaysAgo = today.minus(6, DateTimeUnit.DAY)
            sevenDaysAgo.atStartOfDayIn(UTC)
        }

        DateOptions.ThisMonth -> {
            val today = now.toLocalDateTime(UTC)
            LocalDateTime(
                today.year,
                today.month,
                dayOfMonth = 1,
                today.hour,
                today.minute
            )
        }

        DateOptions.Last30Days -> {
            val thirtyDaysAgo = today.minus(29, DateTimeUnit.DAY)
            thirtyDaysAgo.atStartOfDayIn(UTC)
        }

        DateOptions.Custom -> ""
    }

    val end = when (this) {
        DateOptions.Today -> {
            val start = today.atStartOfDayIn(UTC)
            start.plus(24, DateTimeUnit.HOUR).minus(1, DateTimeUnit.MILLISECOND)
        }

        DateOptions.Yesterday -> {
            val yesterday = today.minus(1, DateTimeUnit.DAY)
            val start = yesterday.atStartOfDayIn(UTC)
            start.plus(24, DateTimeUnit.HOUR).minus(1, DateTimeUnit.MILLISECOND)
        }

        DateOptions.ThisWeek -> {
            today.plus(1, DateTimeUnit.DAY).atStartOfDayIn(UTC).minus(1, DateTimeUnit.MILLISECOND)
        }

        DateOptions.Last7Days -> {
            today.plus(1, DateTimeUnit.DAY).atStartOfDayIn(UTC).minus(1, DateTimeUnit.MILLISECOND)
        }

        DateOptions.ThisMonth -> now.plus(24, DateTimeUnit.HOUR)


        DateOptions.Last30Days -> {
            today.plus(1, DateTimeUnit.DAY).atStartOfDayIn(UTC).minus(1, DateTimeUnit.MILLISECOND)
        }

        DateOptions.Custom -> ""
    }

    return DateTimeRange("${start} ${end}")
}