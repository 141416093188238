package com.picme.sdk2.generated.notification

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*



@Serializable
data class NotificationSubscription(
    val collectionId: CollectionId? = null, 
    val types: NotificationType? = null, 
    val channels: NotificationChannel? = null, 
    val frequency: NotificationFrequency? = null, 
)

/** A record containing the response of a request to put the notification subscription configurations. **/

/** A record containing notification subscription configurations. **/
@Serializable
data class ListNotificationSubscriptionConfigurationsResponse(
    val subscriptions: List<NotificationSubscription> = listOf(), 
)


object NotificationTypeSerializer: KSerializer<NotificationType> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("notification.NotificationType", PrimitiveKind.INT)
    
    override fun deserialize(decoder: Decoder): NotificationType {
        return NotificationType.byValue[decoder.decodeInt()] ?: NotificationType.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: NotificationType) {
        encoder.encodeInt(value.value)
    }
}
@Serializable(NotificationTypeSerializer::class)
enum class NotificationType(val value: Int) {

    SubscriptionManagement(1),

    Uploads(2),

    Referrals(4),

    CollectionJoin(8),

    Comments(16),

    Reactions(32),

    ProductAnnouncements(65536),

    FirstPartyPromotions(131072),
    ;
    companion object {
        val byValue = NotificationType.values().associateBy { it.value }
    }
}


object NotificationChannelSerializer: KSerializer<NotificationChannel> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("notification.NotificationChannel", PrimitiveKind.INT)
    
    override fun deserialize(decoder: Decoder): NotificationChannel {
        return NotificationChannel.byValue[decoder.decodeInt()] ?: NotificationChannel.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: NotificationChannel) {
        encoder.encodeInt(value.value)
    }
}
@Serializable(NotificationChannelSerializer::class)
enum class NotificationChannel(val value: Int) {

    None(0),

    Email(1),

    Text(2),

    App(4),
    ;
    companion object {
        val byValue = NotificationChannel.values().associateBy { it.value }
    }
}


object NotificationFrequencySerializer: KSerializer<NotificationFrequency> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("notification.NotificationFrequency", PrimitiveKind.INT)
    
    override fun deserialize(decoder: Decoder): NotificationFrequency {
        return NotificationFrequency.byValue[decoder.decodeInt()] ?: NotificationFrequency.values().first()
    }
    
    override fun serialize(encoder: Encoder, value: NotificationFrequency) {
        encoder.encodeInt(value.value)
    }
}
@Serializable(NotificationFrequencySerializer::class)
enum class NotificationFrequency(val value: Int) {

    Asap(0),

    Hourly(1),

    Daily(2),

    Weekly(3),

    Monthly(4),

    Anually(5),

    Never(2147483647),
    ;
    companion object {
        val byValue = NotificationFrequency.values().associateBy { it.value }
    }
}

