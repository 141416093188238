package com.picme.components

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.overlayStack
import com.picme.*
import com.picme.actuals.AdCache
import com.picme.actuals.advertisement
import com.picme.sdk2.generated.AdSize
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancelAndJoin
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlin.time.Duration.Companion.seconds


interface ProgressHandle {
    var individualItemProgress: Float
    var itemCount: Int
    var currentIndex: Int
    var image: ImageSource
}

fun ViewWriter.openProgressModal(
    title: String,
    execute: suspend ProgressHandle.() -> Unit,
    onComplete: suspend () -> Unit
) {
    val overlayStack = overlayStack ?: return

    val currentItemProgress = Property(0f)
    val currentItemIndex = Property(0)
    val itemCount = Property(1)
    val currentImage = Property<ImageSource>(Resources.picmelogo)

    val isAdLoaded = Property<Boolean?>(false)

    var cancellable: Job? = null

    val screen = with(overlayStack) {
        dismissBackgroundTheme
        dismissBackground {
            onClick { }
            padded - stack {
                centered - sizeConstraints(width = 24.rem) - FatCardSemantic.onNext - col {
                    row {
                        sizeConstraints(
                            width = 6.rem,
                            height = 6.rem
                        ) - image {
                            scaleType = ImageScaleType.Crop
                            ::source { currentImage() }
                        }
                        expanding - col {
                            val secondsSinceStart = Property(0)
                            launch {
                                while (true) {
                                    delay(1000)
                                    secondsSinceStart.set(secondsSinceStart() + 1)
                                }
                            }
                            row {
                                spacing = 0.3.rem
                                bold - text { content = title }
                                bold - text { ::exists { secondsSinceStart() % 4 > 0 }; content = "." }
                                bold - text { ::exists { secondsSinceStart() % 4 > 1 }; content = "." }
                                bold - text { ::exists { secondsSinceStart() % 4 > 2 }; content = "." }
                            }
                            atEnd - buttonTheme - compact - button {
                                row {
                                    space()
                                    text("cancel")
                                    space()
                                }
                                onClick {
                                    println("cancellable is null ${cancellable == null}")
                                    cancellable?.cancel()
                                }
                            }
                            col {
                                spacing = 0.px
                                subtext {
                                    ::content {
                                        if (itemCount() == 1) currentItemProgress().times(100).toInt().toString()
                                            .plus("%")
                                        else "${currentItemIndex().plus(1)} of ${itemCount()}"
                                    }
                                }
                                sizeConstraints(minHeight = 14.dp) - progressBar {
                                    themeChoice += ThemeDerivation {
                                        it.copy(
                                            foreground = Color.picmePurple,
                                            background = Color.gray.applyAlpha(0.5f),
                                            cornerRadii = CornerRadius.ForceConstant(0.5.rem)
                                        ).withBack
                                    }
                                    ::ratio {
                                        currentItemIndex().plus(currentItemProgress())
                                            .div(itemCount()).times(0.95f).plus(0.05f)
                                    }
                                }
                            }
                        }
                    }

                    separator { }
                    col {
                        spacing = 0.dp
                        // This prevents web from showing a broken link before the ad is loaded in. On iOS, the ad view
                        // must be visible in order for ad mob to load the ad
                        val showWhitespaceInsteadOfAd = shared {
                            isAdLoaded() == false && Platform.current == Platform.Web
                        }
                        sizeConstraints(width = 300.dp, height = 250.dp) - advertisement {
                            ::exists { !showWhitespaceInsteadOfAd() }
                            adUnitId = when (Platform.current) {
//                                Platform.Android -> "ca-app-pub-6829519054466679/5376859530"
                                Platform.Android -> "ca-app-pub-3940256099942544/6300978111" // test adUnitId
                                Platform.iOS -> "ca-app-pub-6829519054466679/9487311157"
                                else -> "ca-app-pub-3940256099942544/2435281174" // Test adUnitId
                            }
                            isAdLoaded bind shared { adLoaded() }.withWrite { }
                            launch {
                                if (Platform.current == Platform.Web) {
                                    while (true) {
                                        isAdLoaded.set(false)
                                        adImgSrc = null
                                        adImgSrc = AdCache.getAd(AdSize.Small, "progress-modal")
                                        isAdLoaded.set(true)
                                        delay(3_000)
                                    }
                                }
                            }
                        }
                        // empty space so that when the add is loading there is a blank space
                        sizeConstraints(width = 300.dp, height = 250.dp) - col {
                            ::exists { showWhitespaceInsteadOfAd() }
                        }
                    }
                }
            }
        }
    }

    cancellable = launchGlobal {
        try {

            // wait for isAdLoaded to be true
            // if it is null ad failed to load skip loading the add
            var maxDelay = 1000
            var timeWaitingForAdToLoade = 0
            while (isAdLoaded() == false && timeWaitingForAdToLoade < maxDelay) {
                delay(100)
                timeWaitingForAdToLoade += 100
            }
            delay(2000)

            object : ProgressHandle {
                override var individualItemProgress: Float by currentItemProgress
                override var itemCount: Int by itemCount
                override var currentIndex: Int by currentItemIndex
                override var image: ImageSource by currentImage

            }.execute()
            println("after execute")
            delay(1_500)
            println("After delay")
        } finally {
            println("Finally")
            asyncGlobal { onComplete() }
            println("After async global")
            overlayStack.removeChild(screen)
            println("After remove child")
        }
    }
}


