package com.picme.sdk2.generated.collection2

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


class CollectionHandler2ApiLive(val endpoints: CollectionHandler2Endpoints, val accessToken: suspend () -> String?): CollectionHandler2Api {

    override suspend fun getDeletedUpload(
        collectionId: CollectionId,
        uploadId: UploadId,
    ): GetDeletedUploadResponse2 = endpoints.getDeletedUpload(
        collectionId = collectionId,
        uploadId = uploadId,
        accessToken = accessToken,
    )

    override suspend fun listLinksFrom(
        linkRelationshipType: LinkRelationshipType,
        collectionId: CollectionId,
    ): ListLinksToResponse2 = endpoints.listLinksFrom(
        linkRelationshipType = linkRelationshipType,
        collectionId = collectionId,
        accessToken = accessToken,
    )

    override suspend fun deleteCollectionCoverPhoto(
        collectionId: CollectionId,
        photoId: String,
    ): Unit = endpoints.deleteCollectionCoverPhoto(
        collectionId = collectionId,
        photoId = photoId,
        accessToken = accessToken,
    )

    override suspend fun listLinksTo(
        linkRelationshipType: LinkRelationshipType,
        collectionId: CollectionId,
    ): ListLinksToResponse2 = endpoints.listLinksTo(
        linkRelationshipType = linkRelationshipType,
        collectionId = collectionId,
        accessToken = accessToken,
    )

    override suspend fun getUpload(
        collectionId: CollectionId,
        uploadId: UploadId,
    ): GetUploadResponse2 = endpoints.getUpload(
        collectionId = collectionId,
        uploadId = uploadId,
        accessToken = accessToken,
    )

    override suspend fun patchUpload(
        collectionId: CollectionId,
        uploadId: UploadId,
        body: PatchUploadBody,
    ): PatchUploadResponse2 = endpoints.patchUpload(
        collectionId = collectionId,
        uploadId = uploadId,
        body = body,
        accessToken = accessToken,
    )

    override suspend fun deleteUpload(
        collectionId: CollectionId,
        uploadId: UploadId,
    ): DeleteUploadResponse2 = endpoints.deleteUpload(
        collectionId = collectionId,
        uploadId = uploadId,
        accessToken = accessToken,
    )

    override suspend fun copyUpload(
        caption: String?,
        sourceCollectionId: CollectionId,
        sourceUploadId: UploadId,
        destinationCollectionId: CollectionId,
        anonymous: Boolean,
        allowDuplicates: Boolean,
    ): CopyUploadResponse2 = endpoints.copyUpload(
        caption = caption,
        sourceCollectionId = sourceCollectionId,
        sourceUploadId = sourceUploadId,
        destinationCollectionId = destinationCollectionId,
        anonymous = anonymous,
        allowDuplicates = allowDuplicates,
        accessToken = accessToken,
    )

    override suspend fun getCollectionModificationStamp(
        collectionId: CollectionId,
    ): GetCollectionModificationStampResponse2 = endpoints.getCollectionModificationStamp(
        collectionId = collectionId,
        accessToken = accessToken,
    )

    override suspend fun putCollectionCoverPhoto(
        collectionId: CollectionId,
        contentType: String,
    ): PutCollectionCoverPhotoResponse = endpoints.putCollectionCoverPhoto(
        collectionId = collectionId,
        contentType = contentType,
        accessToken = accessToken,
    )

    override suspend fun listDeletedUploads(
        collectionId: CollectionId,
        continuation: String?,
        uploadsPerPage: Int32?,
    ): ListDeletedUploadsResponse2 = endpoints.listDeletedUploads(
        collectionId = collectionId,
        continuation = continuation,
        uploadsPerPage = uploadsPerPage,
        accessToken = accessToken,
    )

    override suspend fun restoreDeletedUploads(
        collectionId: CollectionId,
        uploadIds: List<UploadId>?,
    ): RestoreDeletedUploadsResponse2 = endpoints.restoreDeletedUploads(
        collectionId = collectionId,
        uploadIds = uploadIds,
        accessToken = accessToken,
    )

    override suspend fun listUploadKeywords(
        collectionId: CollectionId,
        propertyPath: String,
        keyWordPrefix: String,
    ): ListUploadKeywordsResponse = endpoints.listUploadKeywords(
        collectionId = collectionId,
        propertyPath = propertyPath,
        keyWordPrefix = keyWordPrefix,
        accessToken = accessToken,
    )

    override suspend fun getOrStartPartialZipOfOriginals(
        collectionId: CollectionId,
        uploadIds: List<UploadId>,
    ): GetOrStartPartialZipOfOriginalsResponse2 = endpoints.getOrStartPartialZipOfOriginals(
        collectionId = collectionId,
        uploadIds = uploadIds,
        accessToken = accessToken,
    )

    override suspend fun getOrStartFullZipOfOriginals(
        collectionId: CollectionId,
    ): GetOrStartFullZipOfOriginalsResponse2 = endpoints.getOrStartFullZipOfOriginals(
        collectionId = collectionId,
        accessToken = accessToken,
    )

    override suspend fun sharingConnect(
        authorization: CollectionSharingAuthorizationCode,
        collectionId: String,
    ): Unit = endpoints.sharingConnect(
        authorization = authorization,
        collectionId = collectionId,
        accessToken = accessToken,
    )

    override suspend fun createUpload(
        collectionId: CollectionId,
        body: CreateUploadBody,
        allowDuplicates: Boolean,
    ): CreateUploadResponse2 = endpoints.createUpload(
        collectionId = collectionId,
        body = body,
        allowDuplicates = allowDuplicates,
        accessToken = accessToken,
    )

    override suspend fun listUploads(
        collectionId: CollectionId,
        filterQuery: UploadQuery?,
        continuation: String?,
        uploadsPerPage: Int32?,
    ): ListUploadsResponse2 = endpoints.listUploads(
        collectionId = collectionId,
        filterQuery = filterQuery,
        continuation = continuation,
        uploadsPerPage = uploadsPerPage,
        accessToken = accessToken,
    )

    override suspend fun deleteAllUploads(
        collectionId: CollectionId,
    ): DeleteAllUploadsResponse2 = endpoints.deleteAllUploads(
        collectionId = collectionId,
        accessToken = accessToken,
    )

    override suspend fun requestConnect(
        collectionId: CollectionId,
    ): Unit = endpoints.requestConnect(
        collectionId = collectionId,
        accessToken = accessToken,
    )

    override suspend fun getCollectionRights(
        collectionId: CollectionId,
        targetUserId: UserId?,
    ): GetCollectionRightsResponse2 = endpoints.getCollectionRights(
        collectionId = collectionId,
        targetUserId = targetUserId,
        accessToken = accessToken,
    )

    override suspend fun revokeRights(
        collectionId: CollectionId,
        userId: UserId,
    ): RevokeRightsResponse2 = endpoints.revokeRights(
        collectionId = collectionId,
        userId = userId,
        accessToken = accessToken,
    )

    override suspend fun modifyRights(
        collectionId: CollectionId,
        userId: UserId,
        newRights: Rights,
    ): ModifyRightsResponse = endpoints.modifyRights(
        collectionId = collectionId,
        userId = userId,
        newRights = newRights,
        accessToken = accessToken,
    )

    override suspend fun getCollection(
        collectionId: CollectionId,
    ): GetCollectionResponse2 = endpoints.getCollection(
        collectionId = collectionId,
        accessToken = accessToken,
    )

    override suspend fun patchCollection(
        collectionId: CollectionId,
        body: PatchCollectionBody,
    ): PatchCollectionResponse2 = endpoints.patchCollection(
        collectionId = collectionId,
        body = body,
        accessToken = accessToken,
    )

    override suspend fun deleteCollection(
        collectionId: CollectionId,
    ): DeleteCollectionResponse2 = endpoints.deleteCollection(
        collectionId = collectionId,
        accessToken = accessToken,
    )

    override suspend fun activateInviteCode(
        inviteId: InviteCodeId,
    ): ActivateInviteCodeResponse = endpoints.activateInviteCode(
        inviteId = inviteId,
        accessToken = accessToken,
    )

    override suspend fun generateQrCodePng(
        hostnamePrefix: String,
        pathAndQueryStringPattern: String,
        useLogo: Boolean,
        colorLogo: Boolean,
        pixels: Int32,
    ): CreateQrCodeImageResponse = endpoints.generateQrCodePng(
        hostnamePrefix = hostnamePrefix,
        pathAndQueryStringPattern = pathAndQueryStringPattern,
        useLogo = useLogo,
        colorLogo = colorLogo,
        pixels = pixels,
        accessToken = accessToken,
    )

    override suspend fun listActivatedUsers(
        inviteId: InviteCodeId,
    ): ListActivatedUsersResponse = endpoints.listActivatedUsers(
        inviteId = inviteId,
        accessToken = accessToken,
    )

    override suspend fun listUsers(
        collectionId: CollectionId,
    ): ListUsersResponse2 = endpoints.listUsers(
        collectionId = collectionId,
        accessToken = accessToken,
    )

    override suspend fun generateQrCodeSvg(
        hostnamePrefix: String,
        pathAndQueryStringPattern: String,
        useLogo: Boolean,
        colorLogo: Boolean,
    ): CreateQrCodeImageResponse = endpoints.generateQrCodeSvg(
        hostnamePrefix = hostnamePrefix,
        pathAndQueryStringPattern = pathAndQueryStringPattern,
        useLogo = useLogo,
        colorLogo = colorLogo,
        accessToken = accessToken,
    )

    override suspend fun getInviteCode(
        inviteId: InviteCodeId,
    ): GetInviteCodeResponse = endpoints.getInviteCode(
        inviteId = inviteId,
        accessToken = accessToken,
    )

    override suspend fun patchInviteCode(
        inviteId: InviteCodeId,
        body: PatchInviteCodeBody,
    ): CreateInviteCodeResponse = endpoints.patchInviteCode(
        inviteId = inviteId,
        body = body,
        accessToken = accessToken,
    )

    override suspend fun deleteInviteCode(
        inviteId: InviteCodeId,
    ): Unit = endpoints.deleteInviteCode(
        inviteId = inviteId,
        accessToken = accessToken,
    )

    override suspend fun createRequestInviteCode(
        collectionGlobalId: RecordGlobalId,
        name: String,
        clientInformation: String,
    ): CreateInviteCodeResponse = endpoints.createRequestInviteCode(
        collectionGlobalId = collectionGlobalId,
        name = name,
        clientInformation = clientInformation,
        accessToken = accessToken,
    )

    override suspend fun createSharingInviteCode(
        collectionGlobalId: RecordGlobalId,
        name: String,
        clientInformation: String,
        rightsToOtherUsersUploads: Rights,
    ): CreateInviteCodeResponse = endpoints.createSharingInviteCode(
        collectionGlobalId = collectionGlobalId,
        name = name,
        clientInformation = clientInformation,
        rightsToOtherUsersUploads = rightsToOtherUsersUploads,
        accessToken = accessToken,
    )

    override suspend fun createCollection(
        body: CreateCollectionBody,
    ): CreateCollectionResponse2 = endpoints.createCollection(
        body = body,
        accessToken = accessToken,
    )

    override suspend fun listCollections(
        filters: List<String>?,
        itemsPerPage: Int32,
        continuation: String?,
        forUserId: UserId?,
    ): ListCollectionsResponse2 = endpoints.listCollections(
        filters = filters,
        itemsPerPage = itemsPerPage,
        continuation = continuation,
        forUserId = forUserId,
        accessToken = accessToken,
    )

    override suspend fun recordImpression(
        adId: String,
        collectionId: String?,
        contextId: String?,
    ): Unit = endpoints.recordImpression(
        adId = adId,
        collectionId = collectionId,
        contextId = contextId,
        accessToken = accessToken,
    )

    override suspend fun createInviteCode(
        body: CreateInviteCodeBody,
        linkParentRecord: RecordGlobalId?,
    ): CreateInviteCodeResponse = endpoints.createInviteCode(
        body = body,
        linkParentRecord = linkParentRecord,
        accessToken = accessToken,
    )

    override suspend fun listInviteCodes(
        linkRelationshipType: LinkRelationshipType?,
        linkPrimaryGlobalId: RecordGlobalId?,
    ): ListInviteCodesResponse = endpoints.listInviteCodes(
        linkRelationshipType = linkRelationshipType,
        linkPrimaryGlobalId = linkPrimaryGlobalId,
        accessToken = accessToken,
    )

    override suspend fun listAds(
        collectionId: String?,
        unitTest: Boolean,
    ): ListAdsResponse = endpoints.listAds(
        collectionId = collectionId,
        unitTest = unitTest,
        accessToken = accessToken,
    )

    override suspend fun adServe(
        collectionId: String?,
        adSize: AdSize?,
        contextId: String?,
        unitTest: Boolean,
    ): RedirectResponse = endpoints.adServe(
        collectionId = collectionId,
        adSize = adSize,
        contextId = contextId,
        unitTest = unitTest,
        accessToken = accessToken,
    )
}
