package com.picme.views

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.actuals.webUrl
import com.picme.components.*
import com.picme.sdk2.fetchAndSetBackendUrls
import com.picme.separatorTheme
import kotlinx.serialization.Serializable


@Serializable
enum class Environment { Dev, Staging, Prod; }

val defaultBackendUrl: Environment = webUrl()?.let {
    if (it.contains("localhost")) Environment.Dev
    else when (it.split(".", "//")[1]) {
        "dev" -> Environment.Dev
        "localhost" -> Environment.Dev
        "staging" -> Environment.Staging
        else -> Environment.Prod
    }
} ?: Environment.Prod

val Environment.urlString
    get() = when (this) {
        Environment.Dev -> "https://api.dev.picme.com"
        Environment.Staging -> "https://api.stage.picme.com"
        Environment.Prod -> "https://api.picme.com"
    }

fun frontendUrl(): String {
    if (webUrl()?.contains("localhost") == true) {
        return "http://localhost:8940"
    }
    return webUrl()?.let {
        it.split(".com").first() + ".com"
    } ?: "https://app.picme.com"
}

val devSettings = PersistentProperty("dev-settings", false)
val selectedBackend = PersistentProperty("picme-server", defaultBackendUrl.urlString)

fun ViewWriter.backendUrlSelect() = card - col {
    ::exists { devSettings() }

    reactiveSuspending {
        fetchAndSetBackendUrls(selectedBackend())
    }

    row {
        centered - bold - text {
            content = "Select a backend to connect to"
            align = Align.Center
        }
        expanding - space()
        centered - actionButtonNoText(displayIcon = Icon.close.copy(width = 1.5.rem, height = 1.5.rem)) {
            devSettings set false
            selectedBackend set defaultBackendUrl.urlString
        }
    }
    separatorTheme - separator()

    row {
        radioButton { checked bind selectedBackend.equalTo(Environment.Dev.urlString) }
        text("Dev - ${Environment.Dev.urlString}")
    }
    row {
        radioButton { checked bind selectedBackend.equalTo(Environment.Staging.urlString) }
        text("Staging - ${Environment.Staging.urlString}")
    }
    row {
        radioButton { checked bind selectedBackend.equalTo(Environment.Prod.urlString) }
        text("Prod - ${Environment.Prod.urlString}")
    }
}

