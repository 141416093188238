package com.picme.sdk2.generated.authentication

import com.lightningkite.kiteui.HttpMethod
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline
import kotlinx.datetime.Instant
import com.picme.sdk2.*
import com.picme.sdk2.generated.*


object AuthenticationHandlerEndpointsSerializer : KSerializer<AuthenticationHandlerEndpoints> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("AuthenticationHandlerApi", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: AuthenticationHandlerEndpoints) {
        encoder.encodeString(value.urlWithTrailingSlash)
    }

    override fun deserialize(decoder: Decoder): AuthenticationHandlerEndpoints {
        val string = decoder.decodeString()
        return AuthenticationHandlerEndpoints(string)
    }
}


@Serializable(with = AuthenticationHandlerEndpointsSerializer::class)
data class AuthenticationHandlerEndpoints(val urlWithTrailingSlash: String) {

    val url = urlWithTrailingSlash.trimEnd('/')

    suspend fun continuePasswordReset(
        emailOrPhoneNumber: String,
        confirmationCode: String,
        newPassword: String,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/user/username/${emailOrPhoneNumber.toParamString()}/continue-password",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "confirmationCode" to confirmationCode.toParamString(),
            "newPassword" to newPassword.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun continueSignUpUser(
        emailOrPhoneNumber: String,
        verificationCode: String,
        setTosRead: Boolean,
        name: String?,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/user/username/${emailOrPhoneNumber.toParamString()}/continue-signup",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "verificationCode" to verificationCode.toParamString(),
            "setTosRead" to setTosRead.toParamString(),
            "name" to name.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun resendAccountVerification(
        emailOrPhoneNumber: String,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/user/username/${emailOrPhoneNumber.toParamString()}/verification",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun requestPasswordReset(
        emailOrPhoneNumber: String,
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/user/username/${emailOrPhoneNumber.toParamString()}/password",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun signUpUser(
        emailOrPhoneNumber: String,
        password: String?,
        setTosRead: Boolean?,
        referrerUserId: UserId?,
        userName: String?,
        accessToken: suspend () -> String? = { null }
    ): SignUpUserResponse = fetch(
        url = "$url/user/username/${emailOrPhoneNumber.toParamString()}/signup",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "password" to password.toParamString(),
            "setTosRead" to setTosRead.toParamString(),
            "referrerUserId" to referrerUserId.toParamString(),
            "userName" to userName.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun startAuthentication(
        emailOrPhoneNumber: String,
        password: String,
        accessToken: suspend () -> String? = { null }
    ): StartAuthenticationResponse = fetch(
        url = "$url/session/username/${emailOrPhoneNumber.toParamString()}",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "password" to password.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun checkUsername(
        emailOrPhoneNumber: String,
        accessToken: suspend () -> String? = { null }
    ): CheckUsernameResponse = fetch(
        url = "$url/user/username/${emailOrPhoneNumber.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun getUserProfileUploadUrl(
        contentType: String,
        accessToken: suspend () -> String? = { null }
    ): GetUserProfileUploadUrlResponse = fetch(
        url = "$url/session/user/profile-picture/urls",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "contentType" to contentType.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun deleteUserProfilePicture(
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/session/user/profile-picture",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun getUserProfileInfo(
        userId: UserId,
        collectionId: CollectionId?,
        accessToken: suspend () -> String? = { null }
    ): GetUserProfileInfoResponse = fetch(
        url = "$url/user/id/${userId.toParamString()}",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "collectionId" to collectionId.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun getUserStateDataUrls(
        accessToken: suspend () -> String? = { null }
    ): GetUserStateDataUrlsResponse = fetch(
        url = "$url/session/user/state/urls",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun setUserAttributes(
        name: String?,
        setTosRead: Boolean,
        accessToken: suspend () -> String? = { null }
    ): SetUserAttributesResponse = fetch(
        url = "$url/session/user/attributes",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "name" to name.toParamString(),
            "setTosRead" to setTosRead.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun changeUserPassword(
        oldPassword: String,
        newPassword: String,
        accessToken: suspend () -> String? = { null }
    ): ChangeUserPasswordResponse = fetch(
        url = "$url/session/user/password",
        method = HttpMethod.PUT,
        accessToken = accessToken,
        parameters = mapOf(
            "oldPassword" to oldPassword.toParamString(),
            "newPassword" to newPassword.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun getMyUserInfo(
        accessToken: suspend () -> String? = { null }
    ): GetMyUserInfoResponse = fetch(
        url = "$url/session/user/info",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun refreshUserAuthentication(
        refreshToken: String,
        accessToken: suspend () -> String? = { null }
    ): RefreshUserAuthenticationResponse = fetch(
        url = "$url/session-refresh",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "refreshToken" to refreshToken.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun createReferralInviteCode(
        name: String,
        clientInformation: String,
        accessToken: suspend () -> String? = { null }
    ): CreateInviteCodeResponse = fetch(
        url = "$url/referralInvite",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "name" to name.toParamString(),
            "clientInformation" to clientInformation.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun deleteUser(
        accessToken: suspend () -> String? = { null }
    ): DeleteUserResponse = fetch(
        url = "$url/session/user",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun mergeIntoAccount(
        targetAccountEmailOrPhoneNumber: String,
        targetAccountPassword: String,
        accessToken: suspend () -> String? = { null }
    ): MergeIntoAccountResponse = fetch(
        url = "$url/mergeInto",
        method = HttpMethod.PATCH,
        accessToken = accessToken,
        parameters = mapOf(
            "targetAccountEmailOrPhoneNumber" to targetAccountEmailOrPhoneNumber.toParamString(),
            "targetAccountPassword" to targetAccountPassword.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun listMyReferrals(
        accessToken: suspend () -> String? = { null }
    ): ListReferralsResponse = fetch(
        url = "$url/referrals",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun checkUserAuthentication(
        accessToken: suspend () -> String? = { null }
    ): CheckUserAuthenticationResponse = fetch(
        url = "$url/session",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun invalidateUserTokens(
        accessToken: suspend () -> String? = { null }
    ): Unit = fetch(
        url = "$url/session",
        method = HttpMethod.DELETE,
        accessToken = accessToken,
        parameters = mapOf(
        ),
    )

    suspend fun getRightsOn(
        recordGlobalId: RecordGlobalId,
        accessToken: suspend () -> String? = { null }
    ): GetRightsOnResponse = fetch(
        url = "$url/rights",
        method = HttpMethod.GET,
        accessToken = accessToken,
        parameters = mapOf(
            "recordGlobalId" to recordGlobalId.toParamString(),
        ).filter { it.value != "null" },
    )

    suspend fun authenticateAsGuest(
        setTosRead: Boolean,
        userName: String?,
        accessToken: suspend () -> String? = { null }
    ): AuthenticateAsGuestResponse = fetch(
        url = "$url/guest",
        method = HttpMethod.POST,
        accessToken = accessToken,
        parameters = mapOf(
            "setTosRead" to setTosRead.toParamString(),
            "userName" to userName.toParamString(),
        ).filter { it.value != "null" },
    )
}

