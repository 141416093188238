package com.picme.views.share

import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.picme.PIcon
import com.picme.components.*
import com.picme.iconButtonTheme
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.CollectionUserData

enum class InviteSubView { Invites, InviteDetail }
class ShareView(val collectionId: CollectionId, val onBack: suspend () -> Unit) : PicmeDialog() {
    val invite = Property<ViewInviteInfo?>(null)

    override fun ViewWriter.topBar(close: suspend () -> Unit) = col {
        spacing = 4.dp
        row {
            iconButtonTheme - button {
                smallIcon(PIcon.chevronleft)
                onClick {
                    if (invite() != null) {
                        invite set null
                    } else {
                        onBack()
                    }
                }
            }
            centered - h3 {
                ::content { invite()?.inviteCode?.name ?: "Invites" }
            }
            expanding - space {}
        }
    }

    override val withinCollContext = true
    override fun ViewWriter.content() {
        expanding - col {
            expanding - swapView {
                swapping(
                    current = {
                        if (invite() != null) InviteSubView.InviteDetail
                        else InviteSubView.Invites
                    },
                    views = { currTab ->
                        when (currTab) {
                            InviteSubView.Invites -> with(Invites(collectionId) { invite set it }) { render() }
                            InviteSubView.InviteDetail -> with(QRCodeDetail(
                                qrCodeInfo = shared { invite.awaitNotNull() },
                                onBack = { invite set null }
                            )) { render() }
                        }
                    }
                )
            }
        }
    }

}