package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.sdk2.*
import com.picme.session


@Routable("confirm-email")
class ConfirmEmail : Screen, UnauthScreen {
    var userPassword: String? = null
    val code = Property("")
    val codeFieldError = Property<String?>(null)
    val invalidCode: Property<Boolean> = Property(false)
    override fun ViewWriter.render() {
        authScreen("Confirm Email", Constant(true)) {
            text {
                ::content{ "Enter the verification code sent to your email, ${emailOrPhone.await()}." }
            }

            reactiveScope {
                if (code().isNotBlank()) codeFieldError.value = null
            }
            val tf: TextField
            styledTextField {
                label = "Verification Code"
                hint = "Verification Code"
                content bind code
                error bind codeFieldError
                tf = this.field
            }

            onlyWhen { invalidCode.await() } - danger -
                    centered - text("Invalid verification code.")


            actionOrCancelSection(
                onCancel = { navigator.navigate(LoginOrSignUp()) },
                actionButton = {
                    importantButton("Continue", field = tf) {
                        if (code().isEmpty()) {
                            codeFieldError set "Please enter a verification code."
                            return@importantButton
                        }
                        try {
                            session()?.authentication?.continueSignUpUser(
                                emailOrPhoneNumber = emailOrPhone().lowercase(),
                                verificationCode = code(),
                                name = null,
                                setTosRead = true,
                            ) ?: unauthApi().authenticationHandler.continueSignUpUser(
                                emailOrPhoneNumber = emailOrPhone().lowercase(),
                                verificationCode = code(),
                                name = null,
                                setTosRead = true,
                                accessToken = { null },
                            )
                            if (userPassword == null) {
                                screenNavigator.navigate(LogInScreen)
                                return@importantButton
                            }

                            val loginResponse = session()?.authentication?.startAuthentication(
                                emailOrPhoneNumber = emailOrPhone().lowercase(),
                                password = userPassword!!
                            ) ?: unauthApi().authenticationHandler.startAuthentication(
                                emailOrPhoneNumber = emailOrPhone().lowercase(),
                                password = userPassword!!
                            )
                            val auth = loginResponse.successfulAuthentication
                            if (auth == null) {
                                alert("Error", "More authentication required")
                                return@importantButton
                            }

                            val s =
                                Session(unauthApi(), auth.authenticated, auth.authenticatedUser)
                            session.value = s
                            screenNavigator.navigate(FinishCreateAccount)
                        } catch (e: Exception) {
                            invalidCode set true
                        }
                    }
                }
            )
            gravityCentered - compact - blueLink - button {
                centered - text("Resend verification code")
                onClick {
                    unauthApi().authenticationHandler.resendAccountVerification(emailOrPhone().lowercase())
                    showToast("Verification code sent")
                }
            }
        }
    }
}