package com.picme

import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.views.ViewModifierDsl3
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.gravity

@ViewModifierDsl3
val ViewWriter.gravityCentered get() = gravity(Align.Center, Align.Center)
@ViewModifierDsl3
val ViewWriter.gravityStart get() = gravity(Align.Start, Align.Stretch)
@ViewModifierDsl3
val ViewWriter.gravityEnd get() = gravity(Align.End, Align.Stretch)
@ViewModifierDsl3
val ViewWriter.gravityTop get() = gravity(Align.Stretch, Align.Start)
@ViewModifierDsl3
val ViewWriter.gravityBottom get() = gravity(Align.Stretch, Align.End)
@ViewModifierDsl3
val ViewWriter.gravityTopStart get() = gravity(Align.Start, Align.Start)
@ViewModifierDsl3
val ViewWriter.gravityBottomStart get() = gravity(Align.Start, Align.End)
@ViewModifierDsl3
val ViewWriter.gravityTopCenter get() = gravity(Align.Center, Align.Start)
@ViewModifierDsl3
val ViewWriter.gravityBottomCenter get() = gravity(Align.Center, Align.End)
@ViewModifierDsl3
val ViewWriter.gravityTopEnd get() = gravity(Align.End, Align.Start)
@ViewModifierDsl3
val ViewWriter.gravityBottomEnd get() = gravity(Align.End, Align.End)