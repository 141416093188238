package com.picme.components

import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.overlayStack
import com.picme.FatCardSemantic
import com.picme.dismissBackgroundTheme

fun ViewWriter.showConfirmDialog(
    title: String,
    content: String,
    confirmLabel: String? = null,
    warning: String? = null,
    cancelButtonText: String = "Cancel",
    onConfirm: suspend () -> Unit
) {
    val overlay = overlayStack ?: return

    with(overlay) {
        dismissBackgroundTheme - dismissBackground {
            fun close() {
                overlay.removeChild(this)
            }
            onClick { println("Dismiss bg"); close() }
            centered - padded - stack {
                centered - FatCardSemantic.onNext - sizeConstraints(width = 32.rem) - expanding - col {
                    spacing = 2.rem
                    centered - h4(title)

                    text { ::content { content } }
                    danger - unpadded - text {
                        exists = false
                        ::exists { warning != null }
                        ::content { warning ?: "" }
                    }
                    stack {
                        ::exists { confirmLabel == null }
                        actionOrCancelSection(
                            cancelButtonText = shared { cancelButtonText },
                            onCancel = { close() },
                            actionButton = {}
                        )
                    }
                    stack {
                        ::exists { confirmLabel != null }
                        actionOrCancelSection(
                            cancelButtonText = shared { cancelButtonText },
                            onCancel = { close() },
                            actionButton = {
                                importantButton(shared { confirmLabel ?: "" }) {
                                    onConfirm()
                                    close()
                                }
                            }
                        )
                    }
                }
            }
        }
    }
}


fun ViewWriter.dialogGeneric(content: RView.(close: () -> Unit) -> Unit) {
    val overlay = overlayStack ?: return
    with(overlay) {
        dismissBackgroundTheme - dismissBackground {
            fun close() {
                overlay.removeChild(this)
            }
            onClick { close() }
            centered - padded - stack {
                centered - FatCardSemantic.onNext - sizeConstraints(width = 32.rem) - expanding - col {
                    spacing = 2.rem
                    content { close() }
                }
            }
        }
    }
}