package com.picme.views.share


import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.reactiveScope
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.actionOrCancelSection
import com.picme.components.importantButton
import com.picme.components.showConfirmDialog
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.CollectionUserData
import com.picme.sdk2.generated.Rights
import kotlinx.coroutines.launch


class GuestDetail(val guest: Readable<CollectionUserData>, val collectionId: CollectionId,  val onBack: suspend () -> Unit) : Screen {
    val uploadPermission = Property(true)
    val viewPermission = Property(false)

    val currentRights = Property<Rights?>(null)

    val dirty = sharedSuspending {
        if (currentRights() == null) return@sharedSuspending false
        val uploadDirty = uploadPermission() != (currentRights()!!.value >= 0)
        val viewPermissionDirty = viewPermission() != (currentRights()!!.value >= 3)
        if (uploadDirty && !uploadPermission() && viewPermission() ) {
            showToast("View Permissions Requires Upload Permissions", "In order for guest to view upload permissions are required")
        }
        if( uploadDirty) {
            viewPermission.set(uploadPermission())
        }
        uploadPermission.set(true)
        uploadDirty || viewPermissionDirty
    }



    override fun ViewWriter.render() {
        scrolls - padded - col {
            launch {
                currentRights.set(guest().participationRights)
                val hasPermissionViewLevel = guest().participationRights.value >= 3
                val hasUploadPermission = guest().participationRights.value >= 1
                viewPermission set hasPermissionViewLevel
                uploadPermission set hasUploadPermission
            }
            sizeConstraints(
                  width = 6.rem,
                  height = 6.rem
              ) - centered - buttonTheme - padded - stack {
                  dynamicTheme {
                      val bg = guest().name.userIconColor()
                      ThemeDerivation {
                          it.copy(
                              background = bg,
                              foreground = Color.white
                          ).withBack
                      }
                  }
                  centered - h1 {
                      themeChoice = TextSize42Sementic
                      ::content { guest().name.anonymousIfEmpty().take(1) } }
              }
            centered - h4 { ::content { guest().name.anonymousIfEmpty() } }
                h5("Permissions")
                col {

                    row {
                        gravity(Align.Start, Align.Start) - checkbox {
                            themeChoice += checkboxTheme
                            checked bind uploadPermission
                        }

                        gravity(Align.Start, Align.Start) - col {
                            spacing = 0.2.rem
                            text {
                                themeChoice = SixHundredWeightFontSementic
                                content = "Upload"
                            }
                            subtext("Allow guest to upload to this collection.")
                        }
                    }

                    row {
                        gravity(Align.Start, Align.Start) - checkbox {
                            themeChoice += checkboxTheme
                            checked bind viewPermission
                        }
                        gravity(Align.Start, Align.Start) - col {
                            spacing = 0.2.rem
                            text {
                                themeChoice = SixHundredWeightFontSementic
                                content = "View"
                            }
                            subtext("Allow guest to view this collection.")
                        }
                    }
                }
                h5("Disconnect")
                text("Remove guest from this collection.")
                card - ThemeDerivation {
                    it.copy(foreground = Color.gray, cornerRadii = CornerRadii.ForceConstant(4.rem)).withoutBack
                }.onNext - gravity(Align.Start, Align.Start) - button {
                    spacing = 0.5.rem
                    ThemeDerivation {
                        it.copy(
                            foreground = Color.black, iconOverride = Color.black,
                            outlineWidth = 2.dp, outline = Color.black,
                        ).withoutBack
                    }.onNext - centered - row {
                        centered - sizeConstraints(width = 1.rem, height = 1.rem) - icon {
                            source = PIcon.unlink
                        }
                        ThemeDerivation {
                            it.copy(font = theme.font.copy(weight = 600, size = 14.dp)).withoutBack
                        }.onNext - centered - text {
                            content = "Disconnect Guest"
                        }
                        space()
                    }
                    onClick {
                        showConfirmDialog(
                            title = "Disconnect Collection",
                            content = "Are you sure you want to disconnect ${if (guest().name.isBlank()) guest().name else "this guest"} from this collection?",
                            warning = "This action cannot be undone.",
                            confirmLabel = "Disconnect",
                            onConfirm = {
                                session.awaitNotNull().collection2.revokeRights(
                                    collectionId,
                                    guest().userId
                                )
                                onBack()
                            }
                        )
                    }
                }
                space()
                actionOrCancelSection(
                    onCancel = onBack,
                    actionButton = {
                        importantButton(
                            text = "Save",
                            enabled = dirty
                        )
                        {
                            val updatedRights = calculateRights()
                            val rightsModifiedResponse = session.awaitNotNull().collection2.modifyRights(
                                collectionId,
                                guest().userId,
                                updatedRights
                            )
                            if (rightsModifiedResponse.linksModified > 0) {
                                val updatedGuest = session.awaitNotNull().collection2.listUsers(collectionId).let {
                                    it.users.find { it.userId == guest().userId }
                                }
                                if (updatedGuest != null) {
                                    currentRights.set(updatedRights)
                                }
                            }
                            onBack()
                        }
                    })
                space()
            }
    }

    suspend fun calculateRights():Rights {
        if (!uploadPermission()) return Rights(1)
        if (!viewPermission()) return Rights(2)
        else return Rights(3)
    }

}