package com.picme.views.share

import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.important
import com.picme.PIcon
import com.picme.components.*
import com.picme.iconButtonTheme
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.CollectionUserData

enum class ShareSubView { Invites, InviteDetail, Guests, GuestDetail }
class ShareView(val collectionId: CollectionId, val onBack: suspend () -> Unit) : PicmeDialog() {
    val tab = Property(SelectedTab.First)
    val invite = Property<ViewInviteInfo?>(null)
    val guest = Property<CollectionUserData?>(null)

    override fun ViewWriter.topBar(close: suspend () -> Unit) =col {
        spacing = 4.dp
        row {
            iconButtonTheme - button {
                smallIcon(PIcon.chevronleft)
                onClick{
                    if (invite() != null) {
                        invite set null
                    } else if (guest() != null) {
                        guest set null
                    } else {
                        onBack()
                    }
                }
                }
            centered - h3 { ::content {
                if (invite() != null) invite()?.inviteCode?.name ?: "Invites & Guests"
            else if (guest() != null) "Guest Permissions"
            else "Invites & Guests"
            } }
            expanding - space {}
        }
    }

    override val withinCollContext = true
    override fun ViewWriter.content() {


        tab bind tab.withWrite { invite set null }
        tab bind tab.withWrite { guest set null }

        expanding - col {
            stack {
                ::exists { invite() == null && guest() == null }
                tabSelect(tab, "Invites", "Guests")
            }
            expanding - swapView {
                swapping(
                    current = {
                        if (invite() != null) ShareSubView.InviteDetail
                        else if (tab() == SelectedTab.First) ShareSubView.Invites
                        else if (guest() != null) ShareSubView.GuestDetail
                        else ShareSubView.Guests
                    },
                    views = { currTab ->
                        when (currTab) {
                            ShareSubView.Invites -> with(Invites(collectionId) { invite set it }) { render() }
                            ShareSubView.InviteDetail -> with(QRCodeDetail(
                                qrCodeInfo = shared { invite.awaitNotNull() },
                                onBack = { invite set null }
                            )) { render() }

                            ShareSubView.Guests -> with(Guests(collectionId) {
                                guest set it
                            }) { render() }

                            ShareSubView.GuestDetail -> with(GuestDetail(
                                guest = shared { guest.awaitNotNull() },
                                collectionId = collectionId,
                                onBack = { guest set null }
                            )) { render() }
                        }
                    }
                )
            }
        }
    }

}