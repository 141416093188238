package com.picme.components

import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Writable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewDsl
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.danger
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.dynamicTheme
import com.picme.dangerForeground
import com.picme.fieldSizedTheme
import com.picme.labeledFieldCol
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract


class StyledTextField(
    val field: TextField,
    val text: TextView,
    val error: Writable<String?>
) {
    val content by field::content
    var hint by field::hint
    var label by text::content
}

@ViewDsl
inline fun ViewWriter.styledTextFieldActual(noinline setup: StyledTextField.() -> Unit) {
    val tf: TextField
    val label: TextView

    val error = Property<String?>(null)

    labeledFieldCol - col {
        h4 {
            dynamicTheme { if (error() != null) dangerForeground else null }
            label = this
        }
        sizeConstraints(height = 3.5.rem) - fieldSizedTheme - textInput {
            dynamicTheme { if (error() != null) dangerForeground else null }
            tf = this
        }
        danger - subtext {
            ::content { error() ?: "" }
        }
        setup(StyledTextField(tf, label, error))
    }
}

@OptIn(ExperimentalContracts::class)
@ViewDsl
inline fun ViewWriter.styledTextField(noinline setup: StyledTextField.() -> Unit = {}) {
    contract { callsInPlace(setup, InvocationKind.EXACTLY_ONCE) }; styledTextFieldActual(setup)
}