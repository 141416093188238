package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*

fun Button.smallIcon(icon: Icon, label: String? = null) = centered - sizeConstraints(minWidth = 1.5.rem, minHeight = 1.5.rem) - icon {
    source = icon
    description = label
}
fun Button.smallIcon(icon1: Readable<Icon>, label: String? = null) = centered - sizeConstraints(minWidth = 1.5.rem, minHeight = 1.5.rem) - icon {
    ::source { icon1() }
    description = label
}

fun ViewWriter.actionButtonNoText(
    displayIcon: Icon,
    enabled: Readable<Boolean> = Property(true),
    exists: Readable<Boolean> = Constant(true),
    onClick: suspend () -> Unit
) = iconButtonTheme -  button {
    centered - sizeConstraints(minWidth = 1.5.rem, minHeight = 1.5.rem) - icon { source = displayIcon }
    onClick {
        onClick()
    }
    ::enabled { enabled() }
    ::exists { exists() }
}



fun ViewWriter.actionOrCancelSection(
    onCancel: suspend () -> Unit,
    cancelButtonText: Readable<String> = Constant("Cancel"),
    actionButton: ViewWriter.() -> Unit
) {
    row {
        expanding - secondaryButton {
            field::content {
                cancelButtonText()
            }
            button.onClick("onCancel",null,onCancel)
        }
        expanding - actionButton()
    }
}


fun ViewWriter.importantButton(
    text: String,
    enabled: Readable<Boolean> = Constant(true),
    field: TextField? = null,
    onClick: suspend () -> Unit
) = importantButton(labelText = Constant(text), enabled = enabled, field = field, onClick = onClick)

fun ViewWriter.importantButton(
    labelText: Readable<String>,
    enabled: Readable<Boolean> = Constant(true),
    field: TextField? = null,
    onClick: suspend () -> Unit
) {
    buttonTheme - important - button {
        centered - row {
            spacing = 0.rem
            space()
            centered - h6 { ::content { labelText() } }
            space()
        }
        ::enabled { enabled() }
        onClickAssociatedField1(field = field, action = onClick)
    }
}

class SecondaryButton(
    val field: TextView,
    val button: Button,
    val exists: Writable<Boolean>
) {
    var label by field::content
}

inline fun ViewWriter.secondaryButton(
    noinline setup: SecondaryButton.() -> Unit = {}
) {
    val exists = Property(true)
    val b: Button
    val field: TextView
    stack {
        ::exists { exists() }
        buttonTheme - ThemeDerivation { it.copy(background = Color.bluePurpleGradient).withBack }.onNext - stack { }
        padded - stack {
            spacing = 1.dp
            buttonTheme - ThemeDerivation { it.copy(background = Color.white).withBack }.onNext - button {
                b = this
                centered - row {
                    spacing = 0.rem
                    space()
                    centered - importantForeground.onNext - h6 {
                        field = this
                    }
                    space()
                }
            }
        }
    }

    setup(SecondaryButton(field, b, exists))
}

fun Button.onClickAssociatedField1(
    field: TextField? = null,
    title: String = "Submit",
    icon: Icon = Icon.empty,
    action: suspend () -> Unit
) {
    var lock = false

    suspend fun handleAction() {
        if (!lock) {
            lock = true
            try {
                action()
            } finally {
                lock = false
            }
        }
    }
    val a = Action(
        title = title,
        icon = icon,
        action = {
            handleAction()
        }
    )

    field?.action = a
    this.action = a
}