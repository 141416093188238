    package com.picme
    
    import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.Blob
import com.lightningkite.kiteui.fetch
    
    actual object Resources {
        actual val appleBadge: ImageResource = ImageResource("common/apple-badge.png")
    actual suspend fun favicon(): Blob = fetch("common/favicon.ico").blob()
    actual val googleBadge: ImageResource = ImageResource("common/google-badge.png")
    actual val inter: Font = Font(cssFontFamilyName = "inter", direct = FontDirect(normal = mapOf(500 to "common/inter/Inter-Medium.ttf", 300 to "common/inter/Inter-Light.ttf", 100 to "common/inter/Inter-Thin.ttf", 700 to "common/inter/Inter-Bold.ttf", 400 to "common/inter/Inter-Regular.ttf", 800 to "common/inter/Inter-ExtraBold.ttf", 200 to "common/inter/Inter-ExtraLight.ttf", 900 to "common/inter/Inter-Black.ttf", 600 to "common/inter/Inter-SemiBold.ttf"), italics = mapOf()))
    actual val manrope: Font = Font(cssFontFamilyName = "manrope", direct = FontDirect(normal = mapOf(500 to "common/manrope/Manrope-Medium.ttf", 600 to "common/manrope/Manrope-SemiBold.ttf", 800 to "common/manrope/Manrope-ExtraBold.ttf", 400 to "common/manrope/Manrope-Regular.ttf", 200 to "common/manrope/Manrope-ExtraLight.ttf", 300 to "common/manrope/Manrope-Light.ttf", 700 to "common/manrope/Manrope-Bold.ttf"), italics = mapOf()))
    actual val picMePaigeAdHorizontal: ImageResource = ImageResource("common/PicMe-Paige-Ad-Horizontal.png")
    actual val picMePaigeAdVertical: ImageResource = ImageResource("common/PicMe-Paige-Ad-Vertical.png")
    actual suspend fun picmeIcon(): Blob = fetch("common/picme-icon.svg").blob()
    actual val picmePaige: ImageResource = ImageResource("common/picme-paige.png")
    actual val picmelogo: ImageResource = ImageResource("common/picmelogo.png")
    actual val videoPlaceholder: ImageResource = ImageResource("common/video-placeholder.jpg")
    }