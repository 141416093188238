package com.picme.sso

import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.navigation.Page
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.await
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.ViewModifiable
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.stack
import com.picme.*
import com.picme.sdk2.Session
import com.picme.sdk2.unauthApi
import com.picme.views.FinishCreateAccount
import com.picme.views.acceptingInvite

@Routable("/sso-apple")
class AppleSSOLanding : Page, UnauthScreen {
    @QueryParameter
    val code = Property("")

    override fun ViewWriter.render2(): ViewModifiable {
        return stack {

            suspend fun signIn() {
                unauthApi().authenticationHandler.startAppleAuthentication(
                    appleAuthorizationCode = code(),
                    setTosRead = true,
                ).let { startAuth ->
                    val auth = startAuth.successfulAuthentication ?: return@let
                    // Eventually need to try to merge in account
                    val newSession = Session(unauthApi(), auth.authenticated, auth.authenticatedUser)
                    val all = newSession.collection2.listCollectionsLive().all()
                    println(all.size)

                    session set newSession


                    val shouldAccept =
                        acceptingInvite()?.qrCode?.clientInfo()?.type?.let { it != InviteType.Referral } ?: false

                    if (shouldAccept) acceptQrCode()
                    else {
                        if (startAuth.successfulAuthentication.authenticatedUser.name.isBlank()) {
                            screenNavigator.navigate(FinishCreateAccount)
                        } else {
                            navigateToCollOrLanding()
                        }
                        acceptingInvite set null
                    }
                }
            }

            launchGlobal {
                println("Got code: ${code.await()}")
                signIn()
            }

            fullScreenLoading(Constant(true))
        }
    }

}