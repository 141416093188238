package com.picme.views

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.*
import com.picme.installrefer.InstallReferStartScreen
import com.picme.sdk2.SafeIds
import com.picme.sdk2.generated.CollectionId
import com.picme.sdk2.generated.collection2.PCollection
import com.picme.sdk2.generated.collection2.UploadId
import kotlinx.coroutines.delay
import kotlin.time.Duration.Companion.seconds

@Routable("up-uploading/{urlSafeId}")
class PartialAuthUploadView(
    val urlSafeId: String
) : Screen, UnauthScreen, IncludeTopBar {
    val collectionId = CollectionId(SafeIds.decode(urlSafeId))
    override val title =
        shared { session()?.collection2?.getCollectionLive(collectionId)?.await()?.collection?.name ?: "Upload Photos" }

    override fun ViewWriter.render() = stack {

        val collection = shared {
            session.awaitNotNull().collection2.getCollectionLive(collectionId)().collection
        }

        reactiveSuspending {
            currentCollection.value = collection()
            if (ownsPCollection(collection())) {
                screenNavigator.navigate(CollectionDetail(urlSafeId = urlSafeId))
            }
        }
        spacing = 0.px
        col {
            val images = sharedSuspending {
                var tempImages  = collection().images().all().toRecyclableInfo()
                var count = 0
                while (count++ < 5) {
                    delay(1.seconds)
                    if(tempImages.isNotEmpty()) break
                    tempImages  = collection().images().all().toRecyclableInfo()
                }
                tempImages
            }
            imageRecyclerView(
                colImages = images,
                navToImage = { image ->
                    ImageViewPager(
                        images = sharedSuspending {
                            collection().images().all().toImageDisplayInfo()
                        },
                        incomingImage = image.invoke()
                    )
                },
                selectedImages = CollectionState.selectItems,
                setup = {
                    reactiveSuspending {
                        collection().images().requireIndexLoaded = lastVisibleIndex() + 50
                    }
                }
            )
            expanding - space {
                ::exists { sharedSuspending { collection().images().all().isEmpty()}() }
            }
            atBottomCenter - padded - col {
                sizeConstraints(width = 22.rem) - uploadPhotosButton {
                    uploadExternalFiles(collectionId)
                }
                sizeConstraints(width = 22.rem) - loginOrSignupButton()
            }
            space()
        }
    }
}


fun ViewWriter.loginOrSignupButton(onClick: () -> Unit = {}) = secondaryButton {
    label = "Log in / Sign up"
    reactiveSuspending {
        this.exists set (session()?.isVerifiedAccount() != true)
    }
    button.onClick {
        onClick()
        emailOrPhone set ""
        dialogScreenNavigator.clear()
        mainScreenNavigator.navigate(LoginOrSignUp(true))
    }
}

fun ViewWriter.imageViewPagerOverlayPartial(
    imagesWithAds: Readable<MutableList<ImageDisplayInfo>>,
    curr: Readable<RecyclableInfo>,
    collection: Readable<PCollection>
) {
    val currImage = shared {
        imagesWithAds().first { it.id == curr().id } }

    expanding - gravity(Align.Stretch, Align.Start) - bar - row {
        iconButtonTheme -  centered - button {
            icon { source = PIcon.chevronleft }
            onClick { dialogScreenNavigator.dismiss() }
        }
        space {} in weight(1f)
    }



    atBottom - onlyWhen { curr().isAd } - bar - ThemeDerivation {
        it.copy(background = Color.white, foreground = Color.black).withBack
    }.onNext - row {

        expanding - row {
            iconButtonTheme -  button {
                icon { source = PIcon.trash }
                onClick {
                    showConfirmDialog(
                        title = "Confirm Delete",
                        content = " This item will be deleted from the collection.",
                        confirmLabel = "Delete",
                        onConfirm = {
                            val isLast =
                                imagesWithAds().indexOfFirst { it.id == curr().id } == imagesWithAds().size - 1
                                deleteImages(collection().images().all().filter { it.uploadId.raw == currImage().id })
                            if (isLast) dialogScreenNavigator.dismiss()
                        }
                    )
                }
            }

            expanding - space {
                ::exists { Platform.current != Platform.Web }
            }
            iconButtonTheme -  button {
                ::exists { Platform.current != Platform.Web }
                icon { source = PIcon.qrcode.copy(width = 1.5.rem, height = 1.5.rem) }
                onClick {
                    var list = listOf<Pair<String, Blob>>()
                    val imageToDownload = currImage()
                    openProgressModal(
                        title = "Preparing to share file",
                        execute = {
                            val details = session.awaitNotNull().collection2.getUploadLive(
                                    collectionId = currentCollection()?.collectionId!!,
                                    uploadId = imageToDownload.id.let(::UploadId)
                                )
                            image = imageToDownload.thumbnail
                            val filename = imageToDownload.id.validDownloadableName()
                            val file = fetch(details().getDetailsUri) { downloaded, expected ->
                                individualItemProgress = downloaded.toDouble().div(expected).toFloat()
                            }.blob()
                            list = listOf(filename to file)
                        },
                        onComplete = { ExternalServices.share(list) }
                    )
                }
            }
        }
    }
}




