package com.picme.components

import com.lightningkite.kiteui.ViewWrapper
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.picme.*
import com.picme.views.CollectionList


fun ViewWriter.collectionListMenu() = centered - row {
    val hasCollections = shared {
        session()?.collection2?.listCollectionsLive()?.all()?.isNotEmpty() == true
    }
    centered - stack {
        ::exists { hasCollections() && isSmallScreen() }
        stack {
            centered - sizeConstraints(minWidth = 36.dp, minHeight = 36.dp) - menuButtonTheme - button {
                centered - icon { source = PIcon.menu }
                onClick { dialogScreenNavigator.navigate(CollectionList()) }
            }
        }
    }

    centered - stack {
        ::exists { !hasCollections() }
        centered - sizeConstraints(minWidth = 28.dp, minHeight = 36.dp) - menuButtonTheme - menuButton {
            centered - icon { source = PIcon.menu }
            preferredDirection = PopoverPreferredDirection.belowRight
            requireClick = true
            opensMenu {
                popoverWrapper(width = 22.rem, height = 12.rem) {
                    centered - col {
                        centered - h4("No collections yet \uD83D\uDE22")
                        centered - buttonTheme - important - sizeConstraints(width = 16.rem, height = 42.dp) - button {
                            centered - row {
                                centered - icon(PIcon.add, "Add")
                                centered - h4("Create Collection")
                            }
                            onClick {
                                closePopovers()
                                createCollectionModal()
                            }
                        }
                    }
                }
            }
        }
    }
}

@ViewModifierDsl3
val ViewWriter.menuButtonTheme: ViewWrapper
    get() = iconButtonTheme - ThemeDerivation { it.copy(cornerRadii = CornerRadius.RatioOfSize(0.3f)).withBack }.onNext