package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.sdk2.generated.collection2.CollectionUserData
import com.picme.views.share.guestIconAndName
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

class Filters(val onBack: suspend () -> Unit) : PicmeDialog() {
    override val withinCollContext = true
    override fun ViewWriter.topBar(close: suspend () -> Unit) = dialogTopBar(
        title = title,
        onBack = onBack,
    )

    override val title: Readable<String>
        get() = Constant("Filters")

    val selectedTab = Property(SelectedTab.First)
    val filterDate = Property<DateOptions?>(null)
    val filterGuests = Property<List<CollectionUserData>>(listOf())

    override fun ViewWriter.content() {
        expanding - padded - col {

            launch {
                filterDate set CollectionState.filterDate()
                filterGuests set CollectionState.filterGuests()
            }

            tabSelect(selectedTab, "Date", "Guests")
            expanding - sizeConstraints(minHeight = 20.rem, maxHeight = 32.rem) - swapView {
                swapping(
                    current = { selectedTab() },
                    views = {
                        when (it) {
                            SelectedTab.First -> dateFilter(filterDate)
                            SelectedTab.Second -> guestFilter(filterGuests)
                        }
                    }
                )
            }

            actionOrCancelSection(
                onCancel = onBack,
                actionButton = {
                    importantButton("Apply") {
                        filterDate()?.let { CollectionState.filterDate set it }
                        CollectionState.filterGuests set filterGuests()
                        onBack()
                    }
                }
            )
        }
    }
}


fun ViewWriter.dateFilter(selected: Property<DateOptions?>) = col {
    text("Date uploaded")
    atStart - sizeConstraints(width = 12.rem) - card - menuButton {
        stack {
            atStart - icon { source = PIcon.calendar }
            centered - text { ::content { selected()?.toString() ?: "Select a date" } }
            atEnd - icon { source = Icon.dropdown }

        }
        requireClick = true
        opensMenu {
            sizeConstraints(width = 12.rem) - col {
                DateOptions.entries.forEach {
                    popoverItemTheme - button {

                        dynamicTheme {
                            if (selected() == it) ThemeDerivation {
                                it.copy(background = Color.menuIconBg).withBack
                            }
                            else null
                        }

                        ::exists { it != DateOptions.Custom }
                        atStart - text(it.toString())
                        onClick {
                            selected set it
                            closePopovers()
                        }
                    }
                }
//                separatorTheme - separator { }
//
//                popoverItemTheme - button {
//
//                    dynamicTheme {
//                        if (selected() == DateOptions.Custom) ThemeDerivation {
//                            it.copy(background = Color.menuIconBg).withBack
//                        }
//                        else null
//                    }
//
//                    atStart - text {
//                        content = DateOptions.Custom.toString()
//                    }
//                    onClick {
//                        closePopovers()
//                        dialogGeneric { close ->
//                            h4("Custom Date")
//                            atTop - localDateField {
//    }
//                            actionOrCancelSection(
//                                onCancel = {
//                                    close()
//                                },
//                                actionButton = {
//                                    importantButton("Set") {
//                                        selected set DateOptions.Custom
//                                        close()
//                                    }
//                                }
//                            )
//                        }
//                    }
//                }
            }
        }
    }
}


fun ViewWriter.guestFilter(filterGuests: Property<List<CollectionUserData>>) = expanding - col {
    val sharees = Property<List<CollectionUserData>>(emptyList())
    val loading = Property(false)
    launch {
        loading set true
        val coll = session.awaitNotNull().collection2.getCollectionLive(currentCollection()!!.collectionId)

        session.awaitNotNull().collection2.listUsers(currentCollection()!!.collectionId).let {
            sharees set it.users.filterNot { it.userId == coll().collection.creatorUserId }
                .sortedByDescending { it.participationRights.value }
        }
        loading set false
    }

    padded - col {
        ::exists { loading() }
        space(); space();space()
        centered - activityIndicator()
    }

    padded - col {
        ::exists { !loading() && sharees().isEmpty() }
        centered - text("There are no guests")
    }
    expanding - recyclerView {
        ::exists { !loading() && sharees().isNotEmpty() }
        children(sharees) { guest ->
            col {
                stack {
                    atStart - row {
                        centered - outlinedButton - ThemeDerivation {
                            it.copy(cornerRadii = CornerRadius.RatioOfSize(0.25f)).withBack
                        }.onNext - checkbox {
                            checked bind shared {
                                filterGuests().contains(guest())
                            }.withWrite {
                                launch {
                                    if (it) filterGuests set filterGuests().plusElement(guest())
                                    else filterGuests set filterGuests().minusElement(guest())
                                }
                            }
                        }
                        guestIconAndName(guest)
                    }
                }
            }
        }
    }
}