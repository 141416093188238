package com.picme.views

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.applyAlpha
import com.lightningkite.kiteui.models.times
import com.lightningkite.kiteui.reactive.Constant
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import com.picme.components.PicmeDialog

@Routable("plan")
object Plan : PicmeDialog() {
    override val title = Constant("Subscription")
    override fun ViewWriter.content() {
        expanding - padded - col {
            card - tweakTheme {
                it.copy(
                    background = Color.bluePurpleGradient.applyAlpha(0.2f),
                    outline = Color.darkPurple,
                    spacing = it.spacing * 2
                )
            } - row {
                h6("Free Plan")
                expanding - space {}
                centered - FadedForeground.onNext - text("Free")
            }
        }
    }
}