package com.picme.sdk2.caching

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.ImageRemote
import com.lightningkite.kiteui.reactive.*
import com.picme.imageIfExists
import com.picme.sdk2.generated.authentication.AuthenticationHandlerApi
import com.picme.sdk2.generated.authentication.GetMyUserInfoResponse
import com.picme.sdk2.generated.authentication.SetUserAttributesResponse
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.datetime.Clock
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

interface AuthHandlerApiCacheable : AuthenticationHandlerApi {
    suspend fun putProfilePicture(file: FileReference)
    val profileImage: Readable<ImageRemote?>
}

class AuthenticationHandlerCache(
    val basedOn: AuthenticationHandlerApi,
    val clock: Clock = Clock.System,
) : AuthHandlerApiCacheable, AuthenticationHandlerApi by basedOn {

    private var cacheLife = 5.minutes
    private var lastUserFetch = Instant.DISTANT_PAST
    private val lastUser = LateInitProperty<GetMyUserInfoResponse>()

    override val profileImage: Readable<ImageRemote?> = sharedSuspending {
        var count = 0
        var image:  ImageRemote? = null
        while (count++ < 2) {
            delay(2.seconds)
            image = getMyUserInfo().profilePicture.imageIfExists()
            if (image!= null) break
            count+=1
        }
        lastUserFetch
        image
    }


    override suspend fun getMyUserInfo(): GetMyUserInfoResponse {
        return if (clock.now() - lastUserFetch > cacheLife) {
            lastUserFetch = now()
            val result = basedOn.getMyUserInfo()
            lastUser.value = result
            result
        } else lastUser.await()
    }

    override suspend fun putProfilePicture(file: FileReference) {
        val uploadInfo = getUserProfileUploadUrl(file.mimeType())
        val old = getMyUserInfo().profilePicture
        fetch(
            uploadInfo.profilePictureLocation,
            HttpMethod.PUT,
            body = RequestBodyFile(file)
        )

        var count = 0
        while (count++ < 100) {
            delay(1.seconds)
            if (old != basedOn.getMyUserInfo().profilePicture) break
        }

        delay(3.seconds)
        lastUserFetch = Instant.DISTANT_PAST
        getMyUserInfo()
    }

    override suspend fun deleteUserProfilePicture() {
        return basedOn.deleteUserProfilePicture().also {
            delay(5000)
            lastUserFetch = Instant.DISTANT_PAST
            getMyUserInfo()
        }
    }

    override suspend fun setUserAttributes(name: String?, setTosRead: Boolean): SetUserAttributesResponse {
        return basedOn.setUserAttributes(name, setTosRead).also {
            lastUser.state.onSuccess {
                lastUser.value = it.copy(
                    userData = it.userData.copy(
                        name = name ?: it.userData.name,
                        termsOfServiceRead = if (setTosRead) true else it.userData.termsOfServiceRead,
                    )
                )
            } ?: run {
                lastUserFetch = Instant.DISTANT_PAST
            }
        }
    }
}
