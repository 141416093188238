package com.picme.components

import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.Property
import com.lightningkite.kiteui.reactive.Writable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.picme.*
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract


class StyledPassword(
    val field: TextField,
    val text: TextView,
    val usePlatformSavedPasswords: Writable<Boolean>,
    val error: Writable<String?> = Property(null),
) {
    val content by field::content
    var hint by field::hint
    var label by text::content
}

@ViewDsl
inline fun ViewWriter.styledPasswordActual(noinline setup: StyledPassword.() -> Unit) {
    val showPassword = Property(false)
    val tf: TextField
    val label: TextView

    val usePlatformSavedPasswords = Property(true)
    val error = Property<String?>(null)

    labeledFieldCol - col {
        h4 {
            dynamicTheme { if(error() != null) dangerForeground else null }
            label = this
        }
        // TODO: Warning
        fieldSizedTheme - row {
            dynamicTheme { if(error() != null) dangerForeground else null }
            expanding - centered - textInput {
                tf = this
                ::keyboardHints {
                    if (showPassword()) KeyboardHints.paragraph
                    else if (usePlatformSavedPasswords()) KeyboardHints.password else KeyboardHints.newPassword
                }
            }
            centered - themeFromLast {
                it.copy(
                    outline = Color.white,
                    cornerRadii = CornerRadius.RatioOfSize(2f)
                )
            } - button {
                icon {
                    spacing = 0.5.rem
                    ::source {
                        val icon = if (showPassword()) PIcon.eye else PIcon.eyeoff
                        icon.copy(width = 1.5.rem, height = 1.5.rem)
                    }
                }
                onClick { showPassword.value = !showPassword() }
            }
        }

        danger - subtext { ::content { error() ?: "" } }

        setup(StyledPassword(tf, label, usePlatformSavedPasswords, error))
    }
}

@OptIn(ExperimentalContracts::class)
@ViewDsl
inline fun ViewWriter.styledPassword(noinline setup: StyledPassword.() -> Unit = {}) {
    contract { callsInPlace(setup, InvocationKind.EXACTLY_ONCE) }; styledPasswordActual(setup)
}
