package com.picme.components

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.navigator
import com.picme.*
import com.picme.sdk2.generated.collection2.CreateCollectionBody
import com.picme.session
import com.picme.sdk2.toSafeEncoded
import com.picme.views.CollectionDetail
import com.picme.views.loginOrSignupButton
import kotlinx.serialization.json.JsonNull.content


fun ViewWriter.createCollectionModal() {
    dialogGeneric { close ->
        val collName = Property("")

        h4 {
            content = "Create Collection"
            align = Align.Center
        }

        ifElse(
            condition = { sharedSuspending { session()?.isVerifiedAccount() == true }() },
            ifTrue = {
                formCol {
                    val tf: TextField
                    styledTextField {
                        label = "Collection name"
                        content bind collName
                        hint = "Name"
                        tf = this.field
                        field.requestFocus()
                    }

                    actionOrCancelSection(
                        onCancel = { close() },
                        actionButton = {
                            importantButton("Continue", field = tf,
                                enabled = shared { collName.await().isNotEmpty() }) {
                                // Silently create an unverified account when creating a collection without authentication
                                createAccountIfNewUser()

                                val created = session.awaitNotNull().collection2.createCollection(
                                    CreateCollectionBody(name = collName())
                                )
                                createInitialQrCodes(created.collection)

                                collName set ""
                                close()
                                mainScreenNavigator.reset(CollectionDetail(created.collection.collectionId.raw.toSafeEncoded()))
                            }
                        }
                    )
                }
            },
            ifFalse = {
                col {
                    text {
                        content = "Log in or sign up to create a collection"
                        align = Align.Center
                    }
                    loginOrSignupButton() {
                        close()
                    }
                }
            }
        )
    }
}